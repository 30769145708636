.price-painter {
  width: 37.5rem;
  border: 1px solid get-colour('grey-lighter-60');
  @include padding(all, spacing-03);
  @include flex(column, flex-start, flex-start);

  &--disabled {
    @extend .price-painter;
    width: auto;
    border: none
  }

  &__title {

    width: 100%;
    @include flex(row, center, space-between);
    @include margin(bottom, spacing-06);

    & > * {
      @include margin(right, spacing-02);
    }
 }

  &__new-price {
    width: 100%;
    @include flex(row, flex-end, flex-start);
    @include margin(bottom, spacing-03);

    & > div {
      @include margin(right, spacing-02);
    }

    &-buttons {
      @extend .price-painter__new-price;
      @include margin(bottom, spacing-08);

      & > * {
        @include margin(right, spacing-01);
      }

      &--no-margin {
        @extend .price-painter__new-price-buttons;
        margin: 0;
      }
    }

  }

  &__paint-value {
    width: 100%;
    @include margin(bottom, spacing-08);
    @include flex(row, flex-end, flex-start);

    & > div {
      @include margin(right, spacing-02);
    }
  }

  &__adjust-value {
    width: 100%;
    @include flex(row, flex-end, flex-start);
    & > div {
      @include margin(right, spacing-02);
    }

    & >div:nth-child(2) {
      width: 10.25rem;
    }
  }

  &__adjust-all-prices {
    margin-bottom: -2px;
  }
}