$defaultColour: get-colour('white') !important;

.icon {
  @include flex(row, center, center);
  & svg path {
    fill: $defaultColour;
    color: $defaultColour;
    stroke: $defaultColour;
  }

  &--xsm svg  {
    @extend .icon;
    width: .62rem;
    height: .62rem;
  }

  &--sm svg  {
    @extend .icon;
    width: .75rem;
    height: .75rem;
  }

  &--md svg  {
    @extend .icon;
    width: 1rem;
    height: 1rem;
  }

  &--lg svg  {
    @extend .icon;
    width: 1.5rem;
    height: 1.5rem;
  }

  &--info-tip,
  &--error,
  &--warning {
    display: initial;
    vertical-align: middle;
    width: 1.1rem;
    height: 1.1rem;
    @include margin(left);
  }

  &--disabled {
    cursor: default;

    & svg {
      color: get-colour('btn-primary-disabled-bg') !important;
      fill: get-colour('btn-primary-disabled-bg') !important;
      stroke: get-colour('btn-primary-disabled-bg') !important;
    }
  }
}

.spinning {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}