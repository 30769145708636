.save-multiple-roughs {
  background-color: get-colour('white');
  min-width: 90%;
  margin: 0 auto;
  @include padding(all, spacing-06);

  &__upload-box-container {
    width: 100%;
    @include flex(column, flex-end, flex-start);
    @include margin(vertical, spacing-04);

    @include for-screen-size(desktop, up) {
      width: 60%;
    }

    @include for-screen-size(large-desktop, up) {
      width: 50%;
    }

    & > * {
      @include margin(vertical, spacing-02);
    }
  }

  &__download-template-link {
    @include flex(row, center, flex-start);

    &-icon {
      color: get-colour('secondary');
      @include margin(right);
    }
  }

  &__upload-box {
    width: 100%;
    border: 1px solid get-colour('grey-lighter-60');
    @include padding(all, spacing-02);
  }

  &__table {
    &-cell-value {
      @include flex(row, center, center);
      word-break: break-all;

      &--new {
        color: get-colour('lime-darker-20');
      }
    }
  }
}