.form__container {
  min-width: fit-content;
  width: 100%;
  display: inline-block;
  border-radius: 4px;
  background-color: get-colour('white');
  border: 1px solid get-colour('grey-lighter-80');
  text-align: left;
  @include padding(vertical, spacing-04);
  @include padding(horizontal, spacing-04);

  & > * {
    @include margin(bottom, spacing-04);
  }

  &--no-outline {
    @extend .form__container;
    background-color: transparent;
    border: none;
  }
}

.form__title {
  width: 100%;
  border-radius: 10px;
  display: inline-block;
  @include flex(row, flex-start, flex-start);
  @include margin(bottom, spacing-07);
}

.form__fields {
  & > * {
    @include margin(bottom, spacing-04);
  }
}

.form__field {
  width: 100%;
  @include flex(row, center, flex-end);
  @include margin(bottom, spacing-04);
}

.multi-step-form {
  min-width: 380px;
}

.form__reset-button-container {
  width: 100%;
  @include flex(row, center, flex-end);
}