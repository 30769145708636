.create-order {
  display: inline-flex;
  margin: 0 auto;
  min-width: 0;
  > div {
    min-width: 0;
  }
}

.order-lines {
  background-color: get-colour('grey-lighter-95');
  border: 1px solid get-colour('grey-lighter-60');
  @include flex(row, flex-start, center);

  &__list {
    height: 100%;
    @include padding(all, spacing-02);
    @include flex(column, flex-end, flex-start);

    &-row {
      @include margin(bottom, spacing-02);
      @include flex(row, center, flex-start);
      flex-wrap: nowrap;

      & > * {
        @include margin(right);
      }
    }
  }

  &__item {
    width: 100%;
    @include flex(column, flex-start, flex-start);
    @include padding(all, spacing-03);
    &-columns {
      @include flex(row, flex-start, flex-start);
    }
    &-column {
      @include flex(column, flex-start, flex-start);
      @include padding(horizontal, spacing-02);
      & > * {
        @include margin(bottom, spacing-03);
      }
    }

    &-quantity {
      background-color: get-colour('grey-lighter-90');
      border: 1px solid get-colour('grey-lighter-60');
      @include padding(all, spacing-02);
      @include margin(top, spacing-02);
      &-label {
        display: flex;
        > .subtext {
          @include margin(left, spacing-01);
          line-height: normal;
        }
      }
      & > * {
        @include margin(bottom, spacing-03);
      }
    }

    &-strict-limits {
      & > * {
        @include margin(bottom, spacing-02);
      }
    }

    &-range {
      width: 100%;
      @include flex(row, flex-start, flex-start);
      @include padding();
      & > * {
        @include margin(right);

      }
    }
  }

  &__nav-buttons {
    width: 100%;
    @include flex(row, center, flex-end);
    @include padding(all, spacing-02);
  }
}

.order-field-modal {
  &-content{
    > .modal {
      max-height: 90vh;
      max-width: 90vw;
    }
  }
}