.react-toast-notifications {
  &__toast--info{
    background-color: get-colour('blue-lighter-90') !important;
    .react-toast-notifications__toast__icon-wrapper {
      background-color: get-colour('info');
      padding: 12px 0 12px;
    }
  }
  &__toast--warning{
    background-color: get-colour('orange-lighter-90') !important;
    .react-toast-notifications__toast__warning-wrapper {
      background-color: get-colour('warning');
      padding: 12px 0 12px;
    }
    .react-toast-notifications__toast__content {
      color: get-colour('warning');
    }
  }
}