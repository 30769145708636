.multi-error-modal {

  max-width: 1024px;

  &__error {
    background-color: get-colour('red-lighter-60');
    border-radius: 4px;
    @include padding(all, spacing-02);
    @include margin(vertical, spacing-03);

  &-counter {
    @extend .label;
  }

    &-text {
      @extend .p2;
      color: get-colour('red-darker-20')
    }
  }
}