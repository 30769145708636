.display-message {
  @extend .subtext;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  @include margin(vertical);
  @include padding(all, spacing-02);
  @include flex(row, flex-start, flex-start);

  &--info {
    @extend .display-message;
    color: get-colour('blue-darker-60');
    background-color: get-colour('blue-lighter-60');
    border-color: get-colour('blue-darker-20');
  }

  &--success {
    @extend .display-message;
    color: get-colour('lime-darker-60');
    background-color: get-colour('lime-lighter-60');
    border-color: get-colour('lime-darker-20');
  }

  &--error {
    @extend .display-message;
    color: get-colour('red-darker-60');
    background-color: get-colour('red-lighter-60');
    border-color: get-colour('red-darker-20');
  }
}

.display-message__message {
  @include flex(column, flex-start, flex-start);
}

.display-message__icon {
  height: 100%;
}
