.rough-template-download {
  &__container {
    align-items: center;
  }

  &__description-text {
    @extend .p2;
    color: get-colour('secondary-text');
  }

  &__header {
    border-bottom: 1px solid get-colour('grey-lighter-60');
    @include margin(bottom, spacing-02);
    @include margin(top, spacing-06);
    @include padding(bottom, spacing-04);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;
    @include margin(top, spacing-09);
  }

  &__option {
    @extend .subtext;
    width: 100%;
    @include flex(row, center, flex-start);

    &--span {
      @extend .rough-template-download__option;
      grid-column: 1 / span 2;
      @include margin(bottom, spacing-04);
    }
  }

  &__buttons {
    width: 100%;
    @include flex(row, center, flex-end);
    @include margin(top, spacing-09);
  }
}