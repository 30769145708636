.create-notification-template {

  &__row {
    width: 100%;
    @include flex(row, flex-start, center);

    & > * {
      @include margin(right);
    }
  }

  &__time-offset {
    min-width: 512px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
  }

  &__action-collapsible-row {
    @extend .create-notification-template__row;
    display: grid;
    grid-template-columns: 1fr .3fr;
  }

  &__action-collapsible-btns {
    @include flex(row, center, center);
  }
}