// lVariables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// ----------------------------------------------------------------------
// Colour system
// ----------------------------------------------------------------------
// Base colours
$white: #fff;
$black: #000;
$base-red: #db2929;
$base-orange: #ff8000;
$base-lime: #0cd42c;
$base-blue: #00b3db;
$base-green: #1c5551;
$base-grey: #686868;
// Accent colours
//  - Green
$green-lighter-10: scale-color($base-green, $lightness: 10%);
$green-lighter-20: scale-color($base-green, $lightness: 20%);
$green-lighter-30: scale-color($base-green, $lightness: 30%);
$green-lighter-40: scale-color($base-green, $lightness: 40%);
$green-lighter-80: scale-color($base-green, $lightness: 80%);
$green-lighter-90: scale-color($base-green, $lightness: 90%);
$green-darker-10: scale-color($base-green, $lightness: -10%);
$green-darker-20: scale-color($base-green, $lightness: -20%);
$green-darker-30: scale-color($base-green, $lightness: -30%);
//  - Grey
$grey-lighter-20: scale-color($base-grey, $lightness: 20%);
$grey-lighter-40: scale-color($base-grey, $lightness: 40%);
$grey-lighter-60: scale-color($base-grey, $lightness: 60%);
$grey-lighter-80: scale-color($base-grey, $lightness: 80%);
$grey-lighter-90: scale-color($base-grey, $lightness: 90%);
$grey-lighter-95: scale-color($base-grey, $lightness: 95%);
$grey-darker-20: scale-color($base-grey, $lightness: -20%);
$grey-darker-40: scale-color($base-grey, $lightness: -40%);
$grey-darker-60: scale-color($base-grey, $lightness: -60%);
//  - Blue
$blue-lighter-20: scale-color($base-blue, $lightness: 20%);
$blue-lighter-40: scale-color($base-blue, $lightness: 40%);
$blue-lighter-60: scale-color($base-blue, $lightness: 60%);
$blue-lighter-80: scale-color($base-blue, $lightness: 80%);
$blue-lighter-90: scale-color($base-blue, $lightness: 90%);
$blue-darker-20: scale-color($base-blue, $lightness: -20%);
$blue-darker-40: scale-color($base-blue, $lightness: -40%);
$blue-darker-60: scale-color($base-blue, $lightness: -60%);
//  - Red
$red-lighter-20: scale-color($base-red, $lightness: 20%);
$red-lighter-40: scale-color($base-red, $lightness: 40%);
$red-lighter-60: scale-color($base-red, $lightness: 60%);
$red-lighter-80: scale-color($base-red, $lightness: 80%);
$red-lighter-90: scale-color($base-red, $lightness: 90%);
$red-darker-20: scale-color($base-red, $lightness: -20%);
$red-darker-40: scale-color($base-red, $lightness: -40%);
$red-darker-60: scale-color($base-red, $lightness: -60%);
//  - Orange
$orange-lighter-20: scale-color($base-orange, $lightness: 20%);
$orange-lighter-40: scale-color($base-orange, $lightness: 40%);
$orange-lighter-60: scale-color($base-orange, $lightness: 60%);
$orange-lighter-80: scale-color($base-orange, $lightness: 80%);
$orange-lighter-90: scale-color($base-orange, $lightness: 90%);
$orange-darker-20: scale-color($base-orange, $lightness: -20%);
$orange-darker-40: scale-color($base-orange, $lightness: -40%);
$orange-darker-60: scale-color($base-orange, $lightness: -60%);
//  - Lime
$lime-lighter-20: scale-color($base-lime, $lightness: 20%);
$lime-lighter-40: scale-color($base-lime, $lightness: 40%);
$lime-lighter-60: scale-color($base-lime, $lightness: 60%);
$lime-lighter-80: scale-color($base-lime, $lightness: 80%);
$lime-lighter-90: scale-color($base-lime, $lightness: 90%);
$lime-darker-20: scale-color($base-lime, $lightness: -20%);
$lime-darker-40: scale-color($base-lime, $lightness: -40%);
$lime-darker-60: scale-color($base-lime, $lightness: -60%);

$colours: (
  'white': $white,
  'black': $black,
  'red': $base-red,
  'lime': $base-lime,
  'blue': $base-blue,
  'green': $base-green,
  'grey': $base-grey,
  // - Grey
  'grey-lighter-20': $grey-lighter-20,
  'grey-lighter-40': $grey-lighter-40,
  'grey-lighter-60': $grey-lighter-60,
  'grey-lighter-80': $grey-lighter-80,
  'grey-lighter-90': $grey-lighter-90,
  'grey-lighter-95': $grey-lighter-95,
  'grey-darker-20': $grey-darker-20,
  'grey-darker-40': $grey-darker-40,
  'grey-darker-60': $grey-darker-60,
  // - Green
  'green-lighter-10': $green-lighter-10,
  'green-lighter-20': $green-lighter-20,
  'green-lighter-30': $green-lighter-30,
  'green-lighter-40': $green-lighter-40,
  'green-lighter-80': $green-lighter-80,
  'green-lighter-90': $green-lighter-90,
  'green-darker-10': $green-darker-10,
  'green-darker-20': $green-darker-20,
  'green-darker-30': $green-darker-30,
  // - Blue
  'blue-lighter-20': $blue-lighter-20,
  'blue-lighter-40': $blue-lighter-40,
  'blue-lighter-60': $blue-lighter-60,
  'blue-lighter-80': $blue-lighter-80,
  'blue-lighter-90': $blue-lighter-90,
  'blue-darker-20': $blue-darker-20,
  'blue-darker-40': $blue-darker-40,
  'blue-darker-60': $blue-darker-60,
  // - Red
  'red-lighter-20': $red-lighter-20,
  'red-lighter-40': $red-lighter-40,
  'red-lighter-80': $red-lighter-80,
  'red-lighter-60': $red-lighter-60,
  'red-lighter-90': $red-lighter-90,
  'red-darker-20': $red-darker-20,
  'red-darker-40': $red-darker-40,
  'red-darker-60': $red-darker-60,
  // - Orange
  'orange-lighter-20': $orange-lighter-20,
  'orange-lighter-40': $orange-lighter-40,
  'orange-lighter-60': $orange-lighter-60,
  'orange-lighter-80': $orange-lighter-80,
  'orange-lighter-90': $orange-lighter-90,
  'orange-darker-20': $orange-darker-20,
  'orange-darker-40': $orange-darker-40,
  'orange-darker-60': $orange-darker-60,
  // - Lime
  'lime-lighter-20': $lime-lighter-20,
  'lime-lighter-40': $lime-lighter-40,
  'lime-lighter-60': $lime-lighter-60,
  'lime-lighter-80': $lime-lighter-80,
  'lime-lighter-90': $lime-lighter-90,
  'lime-darker-20': $lime-darker-20,
  'lime-darker-40': $lime-darker-40,
  'lime-darker-60': $lime-darker-60
);

// TODO: Add primary colours variation to avoid using
// colour names in the layout
$theme-colours: (
  //Main
  'primary': $base-green,
  'secondary': $green-lighter-20,
  'text': $grey-darker-20,
  'secondary-text': $grey-lighter-20,
  'error': $base-red,
  'warning': $base-orange,
  'success': $base-lime,
  'info': $base-blue,
  'link': $blue-darker-20,
  //Buttons
  // - Primary
  'btn-primary-default-bg': $base-green,
  'btn-primary-default-text': $white,
  'btn-primary-hover-bg': $green-lighter-10,
  'btn-primary-active-bg': $green-darker-10,
  'btn-primary-disabled-bg': $grey-lighter-60,
  'btn-primary-disabled-text': $grey-lighter-20,
  'btn-primary-disabled-border': $grey-lighter-40,
  // - Secondary
  'btn-secondary-default-bg': $white,
  'btn-secondary-default-text': $green-lighter-20,
  'btn-secondary-default-border': $green-lighter-20,
  'btn-secondary-hover-bg': $green-lighter-10,
  'btn-secondary-hover-text': $white,
  'btn-secondary-active-bg': $green-darker-10,
  'btn-secondary-disabled-bg': $white,
  'btn-secondary-disabled-text': $grey-lighter-20,
  'btn-secondary-disabled-border': $grey-lighter-20,
  // - Warning
  'btn-warning-default-bg': $base-orange,
  'btn-warning-default-text': $white,
  'btn-warning-default-border': $grey-lighter-20,
  'btn-warning-hover-bg': $orange-lighter-20,
  'btn-warning-active-bg': $orange-darker-20,
  'btn-warning-disabled-bg': $grey-lighter-60,
  'btn-warning-disabled-text': $grey-lighter-20,
  // - Error
  'btn-error-default-bg': $base-red,
  'btn-error-default-text': $white,
  'btn-error-hover-bg': $red-lighter-20,
  'btn-error-active-bg': $red-darker-20,
  'btn-error-disabled-bg': $grey-lighter-60,
  'btn-error-disabled-text': $grey-lighter-20,
  // - Action
  'btn-action-default-bg': transparent,
  'btn-action-default-text': $green-lighter-20,
  'btn-action-hover-text': $green-lighter-10,
  'btn-action-active-text': $green-darker-10,
  'btn-action-disabled-text': $grey-lighter-60,
  // - Icon
  'btn-icon-default-bg': $white,
  'btn-icon-default-text': $green-lighter-20,
  'btn-icon-default-border': $green-lighter-20,
  'btn-icon-hover-bg': $green-lighter-10,
  'btn-icon-hover-text': $white,
  'btn-icon-active-bg': $green-darker-10,
  'btn-icon-disabled-bg': $white,
  'btn-icon-disabled-text': $grey-lighter-20,
  'btn-icon-disabled-border': $grey-lighter-20,
  // - Icon no outline
  'btn-icon-no-outline-default-bg': transparent,
  'btn-icon-no-outline-default-text': $green-lighter-10,
  'btn-icon-no-outline-default-border': transparent,
  'btn-icon-no-outline-hover-bg': transparent,
  'btn-icon-no-outline-hover-text': $base-green,
  'btn-icon-no-outline-active-bg': transparent,
  'btn-icon-no-outline-disabled-bg': transparent,
  'btn-icon-no-outline-disabled-text': $grey-lighter-20,
  'btn-icon-no-outline-disabled-border': transparent,
  //Banner
  // - Warning
  'banner-warning': $orange-lighter-80,
  // - Error
  'banner-error': $red-lighter-80,
);

// ----------------------------------------------------------------------
// Spacing
// ----------------------------------------------------------------------
$base-spacing: 1rem;

// ----------------------------------------------------------------------
// globals
// ----------------------------------------------------------------------
$global-border-radius: 4px;
// ----------------------------------------------------------------------
// Typography
// ----------------------------------------------------------------------

//Headers
$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.125rem;
$h4-font-size: 1rem;
$h5-font-size: 0.875rem;

$h1-line-height: 3rem;
$h2-line-height: 2.25rem;
$h3-line-height: 1.75rem;
$h4-line-height: 1.5rem;
$h5-line-height: 1.5rem;

$headers-font-weight: 700;

//Body
//Paragraph 1
$p1-font-size: 1.2rem;
$p1-line-height: 1.5rem;
$p1-font-weight: 400;
//Paragraph 2
$p2-font-size: 1rem;
$p2-line-height: 1.25rem;
$p2-font-weight: 400;
//Paragraph 3
$p3-font-size: 0.85rem;
$p3-line-height: 1rem;
$p3-font-weight: 400;
//Subtext
$subtext-font-size: 0.9rem;
$subtext-line-height: 1rem;
$subtext-font-weight: 400;
//Button
$button-font-size-sm: 0.9rem;
$button-line-height-sm: 1.2rem;
//Label
$label-font-size: 1rem;
$label-font-size-sm: 0.9rem;
$label-line-height: 1.5rem;
$label-font-weight: 600;
//Overline
$overline-font-size: 0.75rem;
$overline-line-height: 1rem;
$overline-font-weight: 700;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

// ----------------------------------------------------------------------
// Components
// ----------------------------------------------------------------------

// Shared (between components)
$transition: 0.1s ease-in-out;
$drop-shadow-md: 0px 10px 20px rgba(0, 0, 0, 0.04),
  0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
$drop-shadow-md-top: 0px -10px 20px rgba(0, 0, 0, 0.04),
  0px -2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
$drop-shadow-xxl: 0px 24px 32px rgba(0, 0, 0, 0.04),
  0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
  0px 0px 1px rgba(0, 0, 0, 0.04);
$drop-shadow-xxl-top: 0px -24px 32px rgba(0, 0, 0, 0.04),
  0px -16px 24px rgba(0, 0, 0, 0.04), 0px -4px 8px rgba(0, 0, 0, 0.04),
  0px 0px 1px rgba(0, 0, 0, 0.04);

// Button
$btn-border-width: 1;
$btn-border: solid ($btn-border-width * 1px);
$btn-border-radius: 2px;
$btn-padding-h: 16;
$btn-padding-v: 4;

// - small
$btn-padding-horizontal-sm: 0.5rem;
$btn-padding-vertical-sm: 0.3125rem;

// - medium
$btn-padding-horizontal-md: 1rem;
$btn-padding-vertical-md: 0.5rem;

// - large
$btn-padding-horizontal-lg: 1.5rem;
$btn-padding-vertical-lg: 0.5rem;
// - because border adds 2px, remove 2px from padding so every btn has the same size
$btn-min-width: 72px;
$btn-hover-drop-shadow: $drop-shadow-md;
$btn-active-drop-shadow: $drop-shadow-md;
$btn-transition: background-color $transition, color $transition,
  box-shadow $transition;

// Input (text input, text area & dropdown)
// - dimensions
$input-line-height: 14px;
$input-font-size: 1rem;
$input-width: 100%;
// - default state
$input-border-color: $grey-lighter-60;
$input-border-style: solid;
$input-border-width: 1px;
$input-border-radius: 6px;
$input-color: $base-grey;
$input-background-color: $white;
// - disabled state
$input-color-disabled: $base-grey;
$input-border-color-disabled: $grey-lighter-80;
$input-background-color-disabled: $grey-lighter-90;
// - focused state
$input-border-color-focused: $green-lighter-20;
$input-drop-shadow-focused: inset 0px 0px 2px $green-lighter-30;

// Topbar
$topbar-height: 40px;

// Sidebar
$sidebar-width-phone: 50px;
$sidebar-width-tablet: 168px;
$sidebar-width-desktop: 244px;
$sidebar-width-phone-collapsed: 50px;
$sidebar-width-tablet-collapsed: 50px;
$sidebar-width-desktop-collapsed: 64px;

// Main area
$main-area-width-phone: calc(100vw - #{$sidebar-width-phone});
$main-area-width-tablet: calc(100vw - #{$sidebar-width-tablet});
$main-area-width-desktop: calc(100vw - #{$sidebar-width-desktop});
$main-area-width-phone-collapsed: calc(100vw - #{$sidebar-width-phone-collapsed});
$main-area-width-tablet-collapsed: calc(100vw - #{$sidebar-width-tablet-collapsed});
$main-area-width-desktop-collapsed: calc(100vw - #{$sidebar-width-desktop-collapsed});