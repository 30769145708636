.calendar {

  &-component {
    border: 1px solid get-colour('grey-lighter-90') !important;
    font-family: inherit !important;

    &__tile {
      position: relative;
      cursor: default !important;
      &-event {
        position: absolute;
        left: 34%;
        bottom: 5px;
        width: 34%;
        height: 2px;
        background-color: get-colour('green-lighter-40');
      }

      &-today {
        cursor: default !important;
        position: relative;
        border-radius: 4px;
        color: get-colour('white');
        box-shadow: $drop-shadow-xxl;
        background-color: get-colour('primary') !important;

        &:hover {
          background-color: get-colour('secondary') !important;
        }
      }
    }
  }

  &__container {
    @include flex(row, flex-start, flex-start);
  }

  &__event {
    width: 100%;
    box-shadow: $drop-shadow-md;
    border-radius: 4px;
    border: 1px solid get-colour('grey-lighter-80');
    @include padding(all, spacing-02);
    display: grid;
    grid-template-columns: 5fr 15fr 3fr;

    & > * {
      @include margin(right, spacing-02);
    }

    &-day {
      @extend .h1;
      color: get-colour('secondary')
    }

    &-name {
      @extend .h5;
    }

    &-date {
      @extend .p3;
      color: get-colour('secondary-text');
    }

    &-badge {
      @extend .overline;
      border-radius: 4px;
      border: 1px solid;
      @include padding(all);

      &--completed {
        @extend .calendar__event-badge;
        color: get-colour('secondary');
        border-color: get-colour('secondary') ;
        background-color: get-colour('lime-lighter-90');
      }
    }

    &-invisible-icon svg {
      fill: get-colour('grey-lighter-40');
    }

    &-list {
      @include flex(column, flex-start, flex-start);
      max-height: 22rem;
      overflow-y: auto;
      @include margin(left, spacing-03);

      & > * {
        @include margin(bottom, spacing-02);
      }
    }
  }
}

.react-calendar__tile abbr{
  display: none;
}

.react-calendar__tile.react-calendar__tile--active {
  border-radius: 4px;
  font-family: inherit !important;
  color: get-colour('black') !important;
  background-color: get-colour('white') !important;
}

.react-calendar__tile.react-calendar__tile--active.calendar-component__tile-today {
  background-color: get-colour('primary') !important;
  color: get-colour('white') !important;
}
