.create-organization {
  width: 100%;
  height: 100%;
  @include flex();
}

.create-organization-buttons {
  @include flex();
  float: right;
}

.create-organization-button {
  @include margin(left, spacing-02);
}

.address-form {
  width: 100%;
  @include flex(row, flex-start, flex-start);

  &__title {
    width: 100%;
    @include flex(row, center, space-between);
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & > * {
      @include padding(all);
      @include margin(top, spacing-02);
    }

    & > fieldset {
      width: calc(100% - 1rem);
      margin-left: 0.5rem;
    }
  }

  &__icons {
    @include flex(row, center, flex-start);
    @include padding(top);

    & svg {
      color: get-colour('green-lighter-10');
    }

    & > * {
      @include margin(right);
    }
  }

  .collapsible__content {
    border: 1px solid get-colour('grey-lighter-60');
    border-top: none;
  }
}
