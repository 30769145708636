.loading-spinner-modal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
  @include flex(row, center, center);

  &__icon {
    height: 100px !important;
    width: 100px !important;
    fill: get-colour('green-lighter-80');
  }
  &__progress {
    &-percentage {
      color: get-colour('white');
      font-weight: bold;
      position: absolute;
    }
  }
}
