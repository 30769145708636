.polished-price-book-modal {
  width: 100%;
  min-width: 940px;
  &__parameters {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    @include padding(right);

    &-button {
      width: 100%;
      @include margin(top, spacing-04);
    }
  }

  & > * {
    @include margin(top, spacing-05);

    & > * {
      @include margin(bottom);
    }
  }

  &__row {
    width: 100%;
    border-bottom: 1px solid get-colour('grey-lighter-60');
    @include padding(vertical, spacing-02);
    @include flex(row, center, flex-start);

    &-icons {
      @include flex(row, center, flex-end);
      @include padding(top, spacing-05);
      & svg {
        fill: get-colour('secondary');
        color: get-colour('secondary');
      }
    }

    & h4 {
      @include padding(top, spacing-05);
    }
  }

  &__download-template {
    @include flex(row, center, flex-start);

    & svg {
      color: get-colour('secondary');
    }
  }
}

.polished-price-book-parameters {
  width: 100%;
  @include flex(column, flex-start, flex-start);

  &__row {
    width: 100%;
    min-width: 700px;
    @include flex(row, center, flex-start);

    & > * {
      @include margin(right);
    }
  }

  &__error {
    @extend .subtext;
    color: get-colour('error');
    @include margin(top);
    @include flex(row, center, flex-start);

    & > * {
      @include margin(right);
    }

    & svg {
      fill: get-colour('error')
    }
  }
}

.order-list__polished-price-book-modal-content > .modal > .modal__content {
  width: 80vw;
  .action-confirmation-modal__form-container {
    max-width: unset;
  }
}