.shipment-details {
  & > *{
    @include margin(bottom);
  }

  &__rough_stones {
    &-label {
      @extend .label;
    }
  }

  &__address-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: end;

    & > .text-area__container {
      grid-column-start: 1;
      grid-column-end: span 2;
    }

    & > * {
      @include padding(all);
      @include margin(top, spacing-02);
    }
  }
}

.select-stones-modal {
  @include flex(column, flex-start, flex-start);
  & > * {
    @include margin(bottom, spacing-03);
  }

  &__title {
    @include flex(row, center, flex-start);

    & > * {
      @include margin(right);
    }
  }

  &__text-area-container {
    @include padding(bottom, spacing-05);

    & > * {
      @include margin(bottom);
    }
  }
}

.shipment-files-modal, .shipment-docs-modal {
  @include flex(column, flex-end, flex-start);

  & > * {
    @include margin(bottom, spacing-03);
  }
}
