.radio-group {
  @include flex(column, flex-start, flex-start);

  &__option {
    @include flex(row, center, flex-start);
  }

  &__label {
    @include input-label();
  }
}

