$admin-colour: #787878;
$seller-colour: #9b8779;
$buyer-colour: #92b6bf;

.dashboard-card {
  position: relative;
  background-color: get-colour('white');
  width: 100%;
  border-top: 6px solid;
  border-radius: 6px;
  box-shadow: $drop-shadow-md;
  //min-width: ;
  @include padding(vertical, spacing-03);
  @include flex(column, center, center);

  &--admin {
    @extend .dashboard-card;
    border-color: $admin-colour;
  }

  &--buyer {
    @extend .dashboard-card;
    border-color: $buyer-colour;
  }

  &--seller {
    @extend .dashboard-card;
    border-color: $seller-colour;
  }

  &__title {
    @extend .h3;
    color: get-colour('secondary-text');
  }

  &__value {
    @extend .h3;
    color: get-colour('text');
  }

  &__icon {
    border: 1px solid get-colour('wite');
    border-radius: 50px;
    position: absolute;
    top: -19px;
    left: 12px;
    width: 30px;
    height: 30px;
    @include flex(row, center, center);

    & svg {
      fill: get-colour('white');
    }

    &--admin {
      @extend .dashboard-card__icon;
      background-color: $admin-colour;
    }

    &--buyer {
      @extend .dashboard-card__icon;
      background-color: $buyer-colour;
    }

    &--seller {
      @extend .dashboard-card__icon;
      background-color: $seller-colour;
    }
  }
}
