.collapsible {
  width: 100%;
  @include padding(bottom);
  @include margin(bottom);
  @include margin(right);
}

.collapsible__header {
  @extend .subtext;
  position: relative;
  border: 1px solid get-colour('green-lighter-90');
  border-radius: 2px;
  width: 100%;
  cursor: pointer;
  background-color: get-colour('white');
  box-shadow: $drop-shadow-md;
  @include padding(vertical, spacing-02);
  @include padding(horizontal);
  @include margin(bottom, spacing-03);

  &--success {
    @extend .collapsible__header;
    border: 1px solid get-colour('success');
  }

  &--error {
    @extend .collapsible__header;
    border: 1px solid get-colour('error');
  }

  &--archived {
    @extend .collapsible__header;
    border: 1px solid get-colour('grey');
  }

  &--disabled {
    @extend .collapsible__header;
    pointer-events: none;
    border: 1px solid get-colour('grey-lighter-80');
    background-color: get-colour('grey-lighter-90');
  }

  &:hover {
    background-color: get-colour('green-lighter-90');
    transition: 0.1s ease-in-out;
  }
}

.collapsible__content {
  height: auto;
  overflow: hidden;
  @include padding();
}

.collapsible__title {
  @include margin(left, spacing-03);
  @include flex(row, center, flex-start);
}

.collapsible__arrow {
  position: absolute;
  left: 15px;
  top: 56%;
  height: 16px;
  width: 16px;
  transform: translate(-50%, -50%);
}
