$inventory-card-padding: spacing-02;

.inventory {
  @include flex(column, flex-start, flex-start);
  max-width: 77rem;
  margin: 0 auto;
  width: 100%;

  &__calendar {
    box-shadow: $drop-shadow-md;
    background-color: get-colour('white');
    @include margin(bottom, spacing-06);
    @include padding(all, $inventory-card-padding);

    &-title {
      @extend .h3;
      color: get-colour('secondary-text');
      @include margin(bottom, spacing-04);
    }

    &-container {
      background-color: get-colour('grey-lighter-95');
      @include padding(all, spacing-02);
      @include margin(bottom, spacing-06);
    }
  }

  &__ranges {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;

    &-container {
      background-color: get-colour('grey-lighter-95');
      @include padding(all, spacing-02);

      &-title {
        @extend .h3;
        color: get-colour('secondary-text');
        @include margin(bottom, spacing-04);
      }
    }

    &-title {
      @extend .h4;
      color: get-colour('secondary-text');
      @include margin(bottom, spacing-06);
    }

    &-row {
      @include flex(row, center, flex-start);

      & > * {
        @include margin(right, spacing-02);
      }
    }

    & > * {
      @include margin(right, spacing-03);
    }
  }

  &__general-ranges {
    height: 100%;
    min-width: 19rem;
    box-shadow: $drop-shadow-md;
    background-color: get-colour('white');
    @include flex(column, flex-start, flex-start);
    border: 1px solid get-colour('grey-lighter-80');
    @include padding(all, $inventory-card-padding);
  }

  &__detailed-ranges {
    width: 100%;
    height: fit-content;
    box-shadow: $drop-shadow-md;
    background-color: get-colour('white');
    border: 1px solid get-colour('grey-lighter-80');
    @include padding(all, $inventory-card-padding);

    &-table {
      width: 100%;
      height: 100%;
      display: grid;
      border: 1px solid get-colour('grey-lighter-90');

      &-title {
        @extend .label;
        @extend .inventory__detailed-ranges-table-column--no-border;
      }

      &-colour {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        border-bottom: 1px solid get-colour('grey-lighter-80');
        @include padding(vertical, spacing-02);

        &-header {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          // grid-column-start: 3;
          @include padding(vertical, spacing-03);
        }
      }

      &-tinge {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid get-colour('grey-lighter-80');
        background-color: get-colour('white');
        @include padding(vertical, spacing-02);

        &-header {
          display: grid;
          grid-template-columns: 1fr 1fr;
          // grid-column-start: 4;
        }
      }

      &-column {
        @extend .p2;
        height: 100%;
        border-bottom: 1px solid get-colour('grey-lighter-80');
        @include padding(horizontal);
        @include padding(vertical);
        @include flex(row, center, center);

        &--no-border {
          @extend .inventory__detailed-ranges-table-column;
          border: 0;
        }
      }

      &-row {

        border-bottom: 1px solid get-colour('grey-lighter-90');
        @include padding(horizontal, spacing-02);


        &:nth-child(odd) {
          @extend .inventory__detailed-ranges-table-row;
          background-color: get-colour('grey-lighter-90');
        }
      }
    }
  }
}

.quad-col {
  grid-template-columns: repeat(4, auto);
  > .inventory__detailed-ranges-table-colour-header{
    grid-column-start: 3;
  }
  > .inventory__detailed-ranges-table-tinge-header {
    grid-column-start: 4;
  }
  & div:nth-child(odd) {
    background-color: get-colour('grey-lighter-90');
  }
}
.tri-col {
  grid-template-columns: repeat(3, auto);
  > .inventory__detailed-ranges-table-colour-header{
    grid-column-start: 2;
  }
  > .inventory__detailed-ranges-table-tinge-header {
    grid-column-start: 3;
  }
  & div:nth-child(3n + 1),
    div:nth-child(3n + 3) {
    background-color: get-colour('grey-lighter-90');
  }
}