.details-page {
  width: 100%;
  height: 100%;
  min-width: fit-content;
  @include flex();

  &__label-value {
    height: 100%;
    @include flex(row, center, flex-start);
  }
}

.details-page__container {
  width: 100%;
  min-width: fit-content;
  height: auto;
  background-color: get-colour('grey-lighter-95');
  border:1px solid get-colour('grey-lighter-60');
  border-radius: 4px;
}

.details-page__header {
  position: sticky;
  top: 0;
  z-index: 95;
  border-bottom: 1px solid get-colour('grey-lighter-80');
  background-color: get-colour('grey-lighter-90');
  @include padding(horizontal, spacing-02);
  @include padding(vertical, spacing-04);
  @include flex(row, center, space-between);

  &-icons {
    border-left: 1px solid get-colour('grey-lighter-60');
    @include flex(row, center, flex-start);
    @include padding(left);

    & > * {
      @include margin(right);
    }
  }
}

.details-page__header-title-container {
  @include flex(column, flex-start, flex-start);
}

.details-page__header-title {
  @extend .label;

  &-row {
    @include flex(row, center, flex-start);

    & > * {
      @include margin(right, spacing-02);
    }
  }
}

.details-page__header-save-btns {
  border-left: 1px solid get-colour('grey-lighter-60');

  button:nth-child(n+2) {
    @include margin(right);
  }
}

.details-page__header-edit-btn {
  @include flex(row, center, center);
}

.details-page__header-title-value {
  @extend .p1;
}

.details-page__header-buttons {
  @include flex(row, right, space-between);

  & > * {
    @include margin(left, spacing-02);
  }
}

.details-page__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.details-page__content-item-card-container {
  width: 98%;
  min-height: 4rem;
  height: auto;
  @include flex(row, center, space-between);
  @include padding(horizontal, spacing-01);
  @include padding(vertical, spacing-04);
}

.details-page__content-item-card {
  width: 100%;
  border-bottom: 1px solid get-colour('grey-lighter-90');
  min-width: fit-content;
  @include flex(row, flex-start, center);
  @include padding(horizontal, spacing-02);
  @include padding(vertical, spacing-02);

  &--span {
    @extend .details-page__content-item-card;
    grid-column: 1 / span 2;
  }

  &--fixed {
    @extend .details-page__content-item-card;
    min-width: 0;
  }

  // &--split {
  //   @extend .details-page__content-item-card;
  //   grid-column: 2;
  // }
}

.details-page__content-item {
  width: 100%;
  height: 100%;
  @include flex(column, flex-start, space-between);
}

.details-page__content-item-label {
  @extend .subtext;
  font-weight: 600;
  @include margin(bottom, spacing-02);
}

.details-page__content-item-value {
  @extend .p1;
  width: 100%;
}

.details-page__content-item-link {
  @extend .p1;
  color: get-colour('link');
}

.details-page__form-btns {
  @include flex(row, center, flex-end);
  & > * {
    @include margin(left);
  }
}
