.input__label {
  @include input-label();
}

.input__helper-text-container {
  width: 100%;
  text-align: start;
}

.input__helper-text {
  @extend .subtext;
  color: get-colour('text');

  &--warning {
    @extend .input__helper-text;
    color: get-colour('warning');
  }

  &--error {
    @extend .input__helper-text;
    color: get-colour('error');
  }

  &--success {
    @extend .input__helper-text;
    color: get-colour('success');
  }
}
