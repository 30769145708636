.dashboard-input-card {
  width: 100%;
  background-color: get-colour('white');
  border-radius: 4px;
  display: grid;
  grid-template-columns: auto 1fr;

  @include padding(all, spacing-02);
  @include drop-shadow(md);

  &__label {
    @extend .h5;
    width: 100%;
    display: grid;
    color: get-colour('text');
    @include flex(row, center, flex-start);
    @include margin(right, spacing-02);
  }

  &__input {
    width: 100%;
  }
}