.create-rough-stone__container {
  width: 100%;
  height: 100%;
  @include flex(row, center, center);
  font-size: 14px;
}



.create-rough-stone__step-progress {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 8px;
  margin: 20px 0 10px;
  li {
    .step_name {
      color: #a4a4a4;
    }
    .step_line {
      display: inline-block;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background: #c3c3c3;
    }
    &.active {
      .step_line {
        background: #2a2a2a;
      }
    }
  }
}

.create-rough-stone__measurement-step {

  > * {
    margin: 5px 0;
  }

  .create-rough-stone__measurement-box {
    border: 1px solid #c3c3c3;
    padding: 5px;
    margin-top: 20px;
    position: relative;

    @include padding(all, spacing-02);
    li {
      line-height: 30px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 12px;
      &:first-of-type {
        font-weight: 600;
        font-size: 10px;
      }
      span {
        display: inline-block;
        text-align: center;
        &:first-of-type {
          width: 30%;
        }
        &:nth-of-type(2) {
          width: 15%;
        }
        &:nth-of-type(3) {
          width: 25%;
        }
        &:nth-of-type(4) {
          width: 30%;
        }
      }
    }
  }
}

.edit-rough-stone__measurement-box {
  @include padding(all, spacing-02);
  @include margin(top, spacing-04);
  border: 1px solid get-colour(grey-lighter-60);
  position: relative;
  width: 100%;
}

.create-rough-stone__measurement-box-header {
  @extend .label;
  width: 100%;
  @include flex(row, center, space-between);
  @include margin(bottom, spacing-04);

  &--view {
    width: 100%;
    @include margin(bottom, spacing-04);
    @extend .create-rough-stone__measurement-list;

    & > *:first-child {
      @extend .label;
    }

    & > .measurements-edit-button-div {
      grid-column: 5;
    }
  }
}

.create-rough-stone__strong-fluor {
  max-width: 70px;
  &--active {
    @extend .create-rough-stone__strong-fluor;

    & input {
      border: 1px solid get-colour('green-darker-20');
      background-color: get-colour('green-lighter-10');
      color: get-colour('white');
      font-weight: 600;
    }
  }
}

.create-rough-stone__measurement-list {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;

  & > * {
    @include margin(bottom, spacing-05);
  }

  & > .input__helper-text-container {
    grid-column: 1 / span 4;
  }
}

.create-rough-stone__measurement-list-header {
  @extend .overline;
}

.create-rough-stone__measurements-list {
  width: 100%;
  background-color: get-colour('white');
  @include padding(all, spacing-02);
  @include flex(column, flex-start, flex-start);
}

.create-rough-stone__measurements-list-item {
  width: 100%;
  border: 1px solid get-colour('grey-lighter-40');
  border-radius: 2px;
  background-color: get-colour('white');
  @include padding(all);
  @include margin(bottom, spacing-04);
}

.create-rough-stone__measurements-row {
  width: 100%;
  flex-wrap: wrap;
  @include flex(column, flex-start, flex-start);
  @include margin(bottom, spacing-04);

  & > * {
    @include margin(right, spacing-02);
  }
}

.create-rough-stone__measurements-title-row {
  @extend .create-rough-stone__measurements-row;
  @include flex(row, flex-start, flex-start);
}

.create-rough-stone__measurements-row-label {
  @extend .label;
}

.create-rough-stone__measurements-title {
  & > * {
    @include margin(right);
  }

  @include flex(row, center, center);
}

.create-rough-stone__measurements-label {
  @extend .h5;
}

.create-rough-stone__measurements-items-row {
  @include padding(vertical);
  @include flex(row, center, flex-start);
}

.create-rough-stone__measurements-item {
  @extend .cursor-pointer;
  display: inline-block;
  border: 1px solid get-colour('grey-lighter-40');
  transition: all .1s ease-in-out;
  border-radius: 2px;
  @include margin(right);
  @include padding(all);

  &:hover {
    color: get-colour('white');
    background-color: get-colour('green-lighter-20')
  }

  &--selected {
   @extend .create-rough-stone__measurements-item;
   border: 1px solid get-colour('green-darker-20');
   color: get-colour('white');
   background-color: get-colour('green-lighter-10');
  }
}

.create-rough-stone__measurements-header {
  @include flex(row, center, flex-start);
}

.create-rough-stone__measurements-footer {
  width: 100%;
  @include flex(column, flex-end, flex-end);
}

.create-rough-stone__measurements-selected-items {
  @extend .p1;
  color: get-colour('grey-lighter-40');
  @include margin(left, spacing-01);
}

.create-rough-stone__step4 {
  .input_label_two {
    border: 1px solid #c3c3c3;
    padding: 10px;
  }
  .upload {
    margin-top: 20px;
    font-weight: 600;
    a {
      text-decoration: none;
      color: #000;
    }
  }
  #FileUpload {
    display: none;
  }
  .btn_upload {
    .name {
      margin-left: 5px;
    }
  }
}

.create-rough-stone__eye-measurement {
  width: 50%;
  @include flex(column, flex-start, flex-start);

  & > * {
    @include margin(bottom, spacing-02);
  }
}

.create-rough-stone__file-upload-link {
  cursor: pointer !important;
  color: get-colour('green-lighter-20');
  position: relative;
  transition: color .1s ease-in-out;
  @include flex(row, center, flex-start);
  @include margin(top, spacing-06);

  &:hover {
    @extend .create-rough-stone__file-upload-link;
    color: get-colour('green');
  }

  & > * {
    @include margin(right);
  }
}

.create-rough-stone__file-upload-input {
  cursor: pointer;
  position: absolute;
  top:0;
  left: 0;
  opacity: 0;
}

.create-rough-stone__upload-file {
  width: 100%;
  border: 1px solid get-colour('grey-lighter-60');
  @include flex(column, flex-start, flex-start);
  @include padding(all, spacing-02);

  &-title {
    @extend .label;
  }

  &-subtext {
    @extend .subtext;
    color: get-colour('grey-lighter-40');
  }
}

.create-rough-stone__total-price {
  width: 100%;
  @include flex(row, center, flex-start);
}