.topBar {
  background-color: get-colour('white');
  width: 100%;
  height: $topbar-height;
  box-shadow: $drop-shadow-md;

  @include flex(row, center, flex-end);
  @include padding(horizontal, spacing-04);
  @include padding(vertical);
}

.topBar__notification-icon {
  position: relative;
  cursor: pointer;
  @include margin(right, spacing-04);

  & svg {
    height: 1.2rem;
    width: 1.2rem;
    fill: get-colour('grey-darker-20');
  }
}

.topBar__notification-counter {
  @extend .overline;
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: -2px;
  left: 10px;
  background-color: get-colour('red');
  border-radius: 25px;
  color: get-colour('white');
  @include flex();
  @include padding();
}
