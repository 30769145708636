.progress-tracker {
  @extend .w-100;
  background-color: get-colour('white');
  border-radius: 4px;
  border: 1px solid get-colour('grey-lighter-80');
  @include flex(row, center, flex-start);
  @include padding(vertical, spacing-04);
  @include margin(bottom);
}

.progress-tracker__step-container {
  width: 100%;
  height: 100%;
  min-height: 2.75rem;
}

.progress-tracker__step {
  width: 100%;
  height: 100%;
  min-height: 2.75rem;
  @include padding(horizontal, spacing-02);
  @include flex(column, flex-start, space-between);
}

.progress-tracker__step-title {
  @extend .subtext;
  width: 100%;
  display: inline;
  color: get-colour('grey-lighter-20');
  @include margin(bottom, spacing-02);
}

.progress-tracker__tracker-line-container {
  width: 100%;
  height: 4px;
  border-radius: 4px;
  transition: 0.2s ease-in;
  background-color: get-colour('grey-lighter-60');

  &--completed{
    @extend .progress-tracker__tracker-line-container;
    background-color: get-colour('lime');
  }

  &--current{
    @extend .progress-tracker__tracker-line-container;
    background-color: get-colour('lime-darker-20');
  }
}

