.text-input__container {
  width: $input-width;
}

.text-input__input {
  width: $input-width;
  background-color: $input-background-color;
  color: $input-color;
  line-height: $input-line-height;
  font-size: $input-font-size;

  @include input-border();
  @include padding(horizontal, spacing-01);

  &:disabled {
    color: $input-color-disabled;
    background-color: $input-background-color-disabled;
  }

  &:focus {
    @include input-border-focused();
  }

  &--sm {
    @extend .text-input__input;
    height: 30px;
  }

  &--md {
    @extend .text-input__input;
    height: 36px;
  }

  &--lg {
    @extend .text-input__input;
    height: 40px;
  }

  &::placeholder {
    font-size: .9rem;
  }
}

.text-input__label {
  @include input-label();
}

.text-input__optional-label {
  color: get-colour('grey-lighter-40');
  @include input-optional-label-margin();
}