.user-list {
  @extend .w-100;
  @include flex(column, center, flex-start);

  &__change-password-modal {
    @include flex(column, flex-start, flex-start);

    & > * {
      @include margin(bottom, spacing-02);
    }

    &-row {
      @include flex(row, center, center);

      &>* {
        @include margin(right, 0);
      }
    }
  }
}
