.provenance {
  width: 500px;
  margin: 0px auto 0;

  &__title {
   @extend .h2;
  }

  & > .form__container {
    padding: 0;
  }

  &__search-input {
    @include flex(row, flex-end, flex-start);
    @include margin(vertical, spacing-08);

    &-button {
      @include margin(left);
    }
  }

  &__details-container {
    width: 100%;
    height: auto;
    @include flex(row, flex-start, center);
  }

  &__details-header {
    @include flex(row, center, space-between);
  }

  &__container {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: get-colour('white');
    border-radius: 4px;
    border: 1px solid get-colour('grey-lighter-60');
    @include flex(column, center, center);

    &-row {
      @extend .p1;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      border-bottom: 1px solid get-colour('grey-lighter-80');
      @include padding(bottom, spacing-03);
      @include margin(vertical, spacing-02);

      &--expand {
        @extend .provenance__container-row;
        grid-template-columns: 1fr 2fr;
      }
    }

    &__container-item {
      @include flex(column, flex-start, flex-start);
    }
  }
}

.provenance-item {
  &__title {
    @extend .h5;
  }

  &__value {
    @include flex(row, center, flex-start);
    @extend .subtext;

    & > * {
      @include margin(right);
    }
  }

  &__status-icon {
    &--success {
      & svg {
        color: get-colour('success');
      }
    }

    &--error, svg {
      color: get-colour('error');
    }
  }
}