.file-upload {
  width: 100%;
  @include margin(bottom, spacing-02);
  @include flex(column, flex-start, flex-start);

  &__title {
    @include flex(row, center, flex-start);
    @include input-label();
    @include margin(bottom, spacing-03);
  }


  &__optional {
    color: get-colour('grey-lighter-40');
    @include input-optional-label-margin();
  }

  &__input {
    &-container {
      width: 100%;
      min-width: max-content;
      background-color: get-colour('grey-lighter-90');
      color: get-colour('secondary-text');
      border: 1px solid get-colour('grey-lighter-60');
      border-radius: 4px;

      @include padding(vertical, spacing-02);
      @include padding(horizontal);
      @include flex(column, center, flex-start);

      &-row {
        width: 100%;
        @include flex(row, center, flex-start);

        & > * {
          @include margin(right);
        }
      }

      & input {
        display: none;
      }

      &--disabled {
        @extend .file-upload__input-container;
        border-color: get-colour('grey-lighter-80');
      }
    }

    &-label {
      @extend .btn-primary;
      min-width: max-content;
      cursor: pointer;
      border-radius: 4px;
      @include flex(row, center, flex-start);
      @include padding(horizontal, spacing-01);
      @include padding(vertical, spacing-02);
      @include margin(right);

      & > * {
        @include margin(right);
      }

      &--disabled {
        @extend .file-upload__input-label;
        @extend .btn, .btn-primary, :disabled;

        &:hover {
          @extend .file-upload__input-label;
          @extend .btn-primary, :disabled;
          cursor: default;
        }
      }
    }

    &-info {
      @include flex(column, flex-start, flex-start);
      width: 100%;
      height: 100%;

      &-description {
        @include flex(row, center, flex-start);

        & > * {
          @include margin(right);
        }
      }

      &-progress-bar {
        width: 100%;
        @include margin(right, spacing-02);

        &-container {
          width: 100%;
          @include flex(row, center, flex-start);
        }
      }
    }
  }

  &__remove-icon {
    color: get-colour('error');
    cursor: pointer;

    &:hover{
      color: scale-color(get-colour('error'), $lightness: -20%);
    }
  }

  &__file-types {
    @extend .subtext;
    color: get-colour('secondary-text');
    @include margin(bottom);
  }

  &__expected-file-name {
    @extend .subtext;
    color: get-colour('secondary-text');
    width: 100%;
    @include flex(row, flex-start, flex-start);
    @include margin(bottom, spacing-02);

    &-icon {
      @include flex(row, center, flex-start);
      @include margin(left);
      &--success svg {
        @extend .file-upload__expected-file-name-icon;
        color: get-colour('success');
      }

      &--warning svg {
        @extend .file-upload__expected-file-name-icon;
        color: get-colour('warning');
      }
    }

    & > * {
      @include flex(row, flex-start, flex-start);
      @include margin(right);
    }
  }

  &__expected-file-list {
    @include flex(column, flex-start, flex-start);
  }
 }