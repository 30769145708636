.payments-report-modal {
  &__container {
    min-width: 490px;

    & > * {
      @include margin(bottom, spacing-03);
    }
   }

   &__clear-all-buttons {
     width: 100%;
     @include flex(row, center, flex-end);
   }

   &__form-buttons {
    width: 100%;
    @include flex(row, center, flex-end);
    @include margin(vertical, spacing-03);
   }
}