.dashboard-charts {
  width: 100%;
  &__row {
    @include padding(all, spacing-02);
    width: 100%;
  }
  &__container {
    position: relative;
    border:1px solid get-colour('grey-lighter-60');
  }
  &__header {
    @extend .h3;
    @include flex(row, center, space-between);
    @include padding(all, spacing-03);
    border-bottom: 1px solid get-colour('grey-lighter-80');
    background-color: get-colour('grey-lighter-90');
  }
  &__label {
    &-description {
      @extend .h4;
      font-weight: 400;
    }
    &-right {
      @include margin(right, spacing-03);
      @include flex(row);
    }
  }
  &__group {
    overflow: auto;
    @include flex(row, center, unset);
    @include padding(all, spacing-03);
  }
  &__chart {
    width: 100%;
    @include padding(all, spacing-02);
    &-title {
      @include flex();
      width: 100%;
      @extend .h4;
    }
  }
  &__order-filter-modal {
    &-content{
      > .modal {
          max-height: 90vh;
          max-width: 90vw;
      }
    }
  }

  .sale-history-chart {
    &__dates {
      @include flex();
      &-input {
        @include margin(all, spacing-02);
      }
    }
    &__radio {
      @include margin(vertical, spacing-04);
      > .radio-group{
        @include flex(row, center, flex-start);
      }
    } 
  }

  .order-breakdown-chart {
    @include margin(all, spacing-02);
    &__radio {
      @include margin(vertical, spacing-04);
      > .radio-group{
        @include flex(row, center, flex-start);
      }
    } 
  }
}
