.create-user__container {
  width: 100%;
  @include flex(row, center, center);
}

.create-user__title {
  @include margin(bottom, spacing-07);
}

.create-user__button {
  float: right;
}
