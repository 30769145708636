.rough-comment {
  position: relative;
  max-width: 31.25rem;
  min-width: 20rem;
  @include margin(bottom, spacing-03);

  &__counter {
    position: relative;
    cursor: pointer;

    &-number {
      @extend .overline;
      min-width: 16px;
      min-height: 16px;
      position: absolute;
      right: 0;
      bottom: 5px;
      background-color: get-colour('grey-lighter-80');
      color: get-colour('text');
      border-radius: 50%;
      @include flex();
      padding: 1px 2px;
    }
  }

  &__icon {
    fill: get-colour('secondary');
    cursor: pointer;

    &--no-comments {
      cursor: pointer;
      fill: get-colour('grey-lighter-40');
    }
  }

  &__title {
    @extend .p3;
    width: 100%;
    background-color: get-colour('primary');
    color: get-colour('white');
    border-radius: 4px 4px 0 0;
    @include flex(row, center, space-between);
    @include padding(horizontal, spacing-01);
    @include padding(vertical, spacing-02);

    &-icons {
      @include flex(row, center, flex-start);
      & > * {
        @include margin(left);
      }
    }
  }

  &__text {
    @extend .p2;
    border-radius:  0 0 4px 4px;
    border: 1px solid get-colour('grey-lighter-40');
    border-top: 0;
    background-color: get-colour('white');
    @include padding(horizontal, spacing-01);
    @include padding(vertical, spacing-02);
  }

  &__list {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    @include margin(bottom, spacing-07);
    @include padding(right, spacing-01);
  }

  &__form {
    border: 1px solid get-colour('grey-lighter-60');
    @include padding(horizontal, spacing-01);
    @include padding(vertical, spacing-02);

    &-title {
      @extend .p3;
      width: 100%;
      background-color: get-colour('secondary');
      color: get-colour('white');
      @include padding(all);

      &--disabled {
        @extend .rough-comment__form-title;
        background-color: get-colour('grey-lighter-60');
        color: get-colour('grey');
      }
    }

    &-save-button {
      width: 100%;
      @include flex(row, center, flex-end);
      @include margin(top);

      & > * {
        @include margin(left);
      }
    }
  }

  &-multi {
    &__new-comment-container {
      @include margin(top);
    }

    &__new-comment-post-button {
      width: 100%;
      @include flex(row, center, flex-end);
      @include margin(top);
    }
  }
}