.transaction-details {
  @include flex(row, flex-start, flex-start);

  & > *{
    width: 50%;
  }

  &__rough-details-container {
    @include margin(left, spacing-02);
  }
}