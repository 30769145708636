.terms-and-conditions-modal {
  &__container {
    height: 75vh;
    max-width: 50vw;
    overflow-y: scroll;
    @include margin(bottom, spacing-02);
  }

  &__header {
    @include flex(column, center);
    @include bold(bold);
  }
  &__body {
    line-height: 20px;
    &-header {
      @include bold(bold);
      text-decoration: underline;
    }
    &-highlight {
      @include bold(bold);
    }
  }
}