.invoice-details {
  width: 100%;
  @include flex(row, flex-start, center);
  &__container {
    width: 100%;
    max-width: 900px;

    & > * {
      @include margin(bottom, spacing-03);
    }
  }
}