.edit-organization-address-form {
  width: 100%;
  @include flex(column, flex-start,flex-start);
}

.edit-organization-address-form__add-address {
  width: 100%;
  @include flex(row, center, flex-end);
}

.edit-organization-address-form__address {
  width: 100%;
  @include flex(row, flex-start, center);

  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & > * {
      @include padding(all);
      @include margin(top, spacing-02);
    }

    & > fieldset {
      width: calc(100% - 1rem);
      margin-left: 0.5rem;
    }
  }

  .collapsible__content {
    border: 1px solid get-colour('grey-lighter-60');
    border-top: none;
  }
}

.edit-organization-address-form__userdoc-icons {
  @extend .edit-organization-address-form__icons;
  position: relative;
  top: 26px;

}

.edit-organization-address-form__icons {
  @include flex(row, center, flex-start);
  @include margin(top, spacing-01);

  & > * {
    @include margin(right);
  }

  & svg {
    color: get-colour('green-lighter-10');
  }
}