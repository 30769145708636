.text-area__container {
  width: $input-width;
}

.text-area__input {
  width: $input-width;
  background-color: $input-background-color;
  color: $input-color;
  // line-height: 20px;
  font-size: $input-font-size;
  @include input-border();
  @include input-padding();

  &:focus {
    @include input-border-focused();
  }

  &:disabled {
    color: $input-color-disabled;
    background-color: $input-background-color-disabled;
  }
}

.text-area__label {
  @include input-label();
}

.text-area__optional-label {
  color: get-colour('grey-lighter-40');
  @include input-optional-label-margin();
}
