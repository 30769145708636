.tabs {
  width: 100%;
  height: fit-content;
  background-color: get-colour('white');
  border: 1px solid get-colour('grey-lighter-60');
  border-radius: 2px;
  @include padding(all, spacing-01);
  @include flex(column, flex-start, flex-start);

  &__header {
    background-color: get-colour('grey-lighter-95');
    border-radius: 4px;
    @include flex(row, center, flex-start);
    @include padding(left);
    @include padding(vertical);
    @include margin(top, spacing-02);

    &-item {
      @extend .h4;
      border-bottom: 2px solid get-colour('grey-lighter-60');
      border-radius: 4px;
      cursor: pointer;
      transition: border-bottom .2s ease-in-out,
      background-color .2s ease-in-out,
      box-shadow .2s ease-in-out;
      @include padding(all);
      @include margin(right);

      &--active {
        @extend .tabs__header-item;
        background-color: get-colour('white');
        color: get-colour('text');
        box-shadow: $box-shadow-sm;
        border-bottom: 2px solid get-colour('green-lighter-20');
      }

      &--disabled {
        @extend .tabs__header-item;
        color: get-colour('grey-lighter-80');
        cursor: default;
        pointer-events: none;
    
        &:after {
          color: get-colour('grey-lighter-20');
        }
      }
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    overflow: auto;
    @include flex(column, flex-start, flex-start);
    @include margin(bottom);
  }
}

