// react-select styling works by passing a class prefix (dropdown in this case)
// to the component to be used to overwrite defaults classes
// if you global search for dropdown__option for example chances are
// that nothing will come up in the codebase, because .dropdown__option is generated
// and inject into DOM by react-select on render

.dropdown {
  width: 100%;
}

.dropdown__header {
  @include flex(row, center, space-between);
}

.dropdown__label {
  @include input-label();
}

.dropdown__top-actions {
  @include flex(row, center, flex-start);
}

.dropdown-container {
  height: auto;
  border: 0;

  &:focus-visible {
    border: 0;
    outline: 0;
  }

  &.form-error {
    > div {
      border-color: get-colour('error');
    }
  }
  &.form-warning {
    > div {
      border-color: get-colour('warning');
    }  }


  &--sm {
    @extend .dropdown-container;
    min-height: 31px;
  }

  &--md {
    @extend .dropdown-container;
    min-height: 36px;
  }

  &--lg {
    @extend .dropdown-container;
    min-height: 40px;
  }
}

.dropdown__control {
  width: 100%;
  height: 100%;
  background-color: $input-background-color;
  color: $input-color;
  font-size: 1rem;
  line-height: 20px;
  min-height: 0 !important;
  cursor: pointer;

  @include input-border();

  &:active,
  &:focus,
  &--is-focused,
  &--menu-is-open {
    @include input-border-focused();
  }

  &:disabled,
  &--is-disabled {
    color: $input-color-disabled !important;
    background-color: $input-background-color-disabled !important;
  }

  @include flex(row, center, space-between);
  // @include padding(vertical, spacing-01);
  @include padding(horizontal, spacing-01);
}

.dropdown__option-list {
  display: none;
  position: absolute;

  // @include margin(top);
  &--open {
    display: block;
  }
}

.dropdown__menu-list {
  @include padding(all, none, $important: true);
}

.dropdown__menu {
   @include margin(all, none, true);
}

.dropdown__option {
  @extend .subtext;
  background-color: get-colour('white') !important;
  border-bottom: 1px solid get-colour('grey-lighter-60');
  border-left: 5px solid get-colour('white') !important;
  color: get-colour('text') !important;
  width: 100%;
  text-align: start;

  @include padding(vertical, spacing-02);
  @include padding(horizontal, spacing-02);

  &:hover {
    cursor: pointer;
    background-color: get-colour('white') !important;
    border-left: 5px solid get-colour('green-lighter-30') !important;
  }

  &--is-disabled {
    cursor: initial;
    pointer-events: none;
    color: get-colour('grey-lighter-60') !important;
  }

  &--is-selected {
    background-color: get-colour('white') !important;
    border-left: 5px solid get-colour('green-lighter-20') !important;
  }
}

.dropdown__value-container {
  @include padding(all, none, $important: true);
}

.dropdown__placeholder {
  @extend .p2;
  text-align: left;
}

.dropdown__indicators {
  height: 100%;
}

.dropdown__group {
  padding-bottom: 8px;
  padding-top: 8px;
  box-sizing: border-box;
  
  &-heading {
    cursor: default;
    display: block;
    color: hsl(0, 0%, 60%);
    font-size: 75%;
    font-weight: 500;
    margin-bottom: 0.25em;
    padding-left: 12px;
    padding-right: 12px;
    text-transform: uppercase;
    box-sizing: border-box;
  }
}
