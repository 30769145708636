.upload-adv-file-row {
  display: grid;
  grid-template-columns: 0.7fr 0.3fr 0.5fr 1fr 0.11fr;
  border-bottom: solid 1px get-colour('grey-lighter-80');
  align-items: center;
  @include margin(bottom, spacing-02);
  @include padding(bottom, spacing-02);
  @include padding(vertical, spacing-02);

  &__header {
    @extend .upload-adv-file-row;
    align-items: flex-start;
  }

  & span {
    width: fit-content;
    @include margin(right, spacing-03);
    @include flex(row, center, center);
  }

  &__icons {
    width: 56px;
    height: 100%;
    @include flex(row, center, flex-start);
    @include margin(left);

    & > * {
      min-width: 24px;
    }

    &--success {
      @extend .upload-adv-file-row__icons;
      & svg {
        fill: get-colour('lime-darker-20');
      }
    }

    &--error {
      @extend .upload-adv-file-row__icons;
      fill: get-colour('error');
    }
  }

  &__rough-stone {
    width: 30vw;
  }


  &__file-name {
    overflow-wrap: anywhere;

    &--file-exists {
      @extend .upload-adv-file-row__file-name;
      color: get-colour('warning');
    }
  }

}