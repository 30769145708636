.colour-mix-grid {
  &__grid-container {
    display: grid;
    grid-template-columns: 3.5rem repeat(10, fit-content(50px)) 2.2rem;
    gap: 4px;
    grid-auto-rows: minmax(1rem, auto);
    align-items: center;
    @include margin(bottom, spacing-15);
  }

  &__input {
    max-width: 50px;
  }

  &__label {
    @extend .label;
    max-width: 50px;

    &-colour {
      @extend .colour-mix-grid__label;
    }

    &-rap {
      @extend .colour-mix-grid__label;
    }

    &-total {
      @extend .p2;
    }
  }

  &__validation-error {
    @extend .input__helper-text--error;
    grid-column: 1 / 13;
    text-align: right;
  }
}