.assign-stones {
  &__grid {
    width: 80vw;
    display: grid;
    grid-template-columns: 20vw 30vw auto;
    &-header{
      border-bottom: solid 1px get-colour('grey-lighter-80');
      @include flex(row, center, flex-start);
      @include margin(bottom, spacing-02);
      @include padding(bottom, spacing-02);    }
    &-row {
      border-bottom: solid 1px get-colour('grey-lighter-80');
      @include flex(row, center, flex-start);
      @include margin(bottom, spacing-02);
      @include padding(bottom, spacing-02);
    }
    // @include flex(row, center, space-between);
    &-name{
      // flex-grow: 1;
    }
    &-users {
      // flex-grow: 2;
    }
  }
  &-modal {
    &-content {
      .action-confirmation-modal__form-container {
        max-width: unset;
      }
    }
  }
}

.qc-badge {
  > .badge-counter {
    width: 1rem;
    height: 1rem;
    top: -12px;
    right: -12px;
    font-size: 10px;
  }
}