.toggle-switch {
  width: 100%;
  @include flex(row, center, flex-start);
  @include margin(bottom, spacing-02);
}

.toggle-switch input {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-switch__label {
  cursor: pointer;
  text-indent: -9999px;
  width: 32px;
  min-width: 32px;
  height: 18px;
  background: get-colour('grey-lighter-20');
  display: block;
  border-radius: 100px;
  position: relative;
  @include margin(right, spacing-02);

  &:after {
    content: '';
    position: absolute;
    top: 6%;
    left: 6%;
    width: 48%;
    height: 78%;
    background: get-colour('white');
    border-radius: 90px;
    transition: 0.3s;
  }

  &--checked {
    @extend .toggle-switch__label;
    background: get-colour('green-lighter-20');

    &:after {
      left: 98%;
      transform: translateX(-100%);
    }
  }

  &--disabled {
    @extend .toggle-switch__label;
    background: get-colour('grey-lighter-80');
    cursor: default;
    pointer-events: none;

    &:after {
      background: get-colour('grey-lighter-20');

    }
  }
}

.toggle-switch__label:active:after {
  width: 96%;
}
