.order-pricing {
  width: 100%;
}

.order-pricing-ranges-settings {
  &__row {
    width: 30%;
    min-width: 19rem;
    @include flex(row, center, flex-start);
    @include margin(bottom, spacing-02);

    & > * {
      @include margin(right);
    }
  }
}

.orders-pricing-grid {
  background-color: get-colour('grey-lighter-95');
  @include padding(all, spacing-04);

  @include flex(column, flex-start, flex-start);
  &__cell {
    width: 6.3rem;
  }

  &__bottom-label {
    @extend .subtext;
    color: get-colour('grey-lighter-20');
  }

  &__row {
    width: 100%;
    height: fit-content;
    @include flex(row, flex-start, flex-start);
    @include margin(bottom, spacing-02);

    & > * {
      @include margin(right);
    }
  }

  &__colour-label {
    @extend .subtext;
    @include flex(row, center, flex-end);
    @include margin(right, spacing-02);
    min-width: 45px; // Just beyond the length of string D2AW
    align-self: center;
  }
  .form-warning {
    input {
      border-color: get-colour('warning') !important;
    }
  }
  .form-error {
    input {
      border-color: get-colour('error') !important;
    }
  }
  .input__helper-text-container {
    @include margin(top, spacing-01);
  }
}

.order-pricing-range {
  background-color: get-colour('grey-lighter-95');

  &__settings {
    width: 45%;
    min-width: 19rem;
    & > * {
      @include margin(bottom, spacing-03);
    }

    &-margin {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &-input {
        width: 100%;
      }

      &-button {
        width: 100%;
        @include flex(row, flex-end, center);
      }

      & > * {
        @include margin(right);
      }
    }

    &-weight {
      @include flex(row, center, flex-start);
      & > * {
        @include margin(right);
      }
    }
  }
}

.order-pricing-ranges {
  background-color: get-colour('grey-lighter-95');
  border : 1px solid get-colour('grey-lighter-60');
  min-width: 0;
  @include padding(all, spacing-02);
  @include flex(row, flex-start, flex-start);

  &__row {
    @include flex(row, flex-start, space-between);
  }

  &__list {
    position: sticky;
    top: 92px;
    @include margin(right,spacing-05);
    @include flex(column, flex-start, flex-start);

    &-row {
      @include margin(bottom, spacing-02);
      @include flex(row, center, flex-start);
      width: 100%;
      flex-wrap: nowrap;

      & > * {
        @include margin(right);
      }

      & button {
        width: 100%;
      }
    }
  }

  &__range {
    min-width: 0;
  }

  &-settings {
    min-width: 20rem;
  }
}

.order-pricing-fluor-grid {
  background-color: get-colour('grey-lighter-95');
  @include padding(top, spacing-04);
  @include padding(horizontal, spacing-02);

  &__row {
    @include flex(row, stretch, flex-start);
    height: fit-content;
    @include margin(bottom, spacing-06);
  }

  &__collapsible {
    @include flex(row, center, flex-start);
    @include margin(right, spacing-02);
    width: 45px;

    &-icon {
      @include flex(column, flex-start, center);
      transition: all .3s ease-in-out;

      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;

      &--expanded {
        @extend .order-pricing-fluor-grid__collapsible-icon;
        @include flex(row, center, center);
        transform: rotate(90deg);
      }
    }
  }

  &__column {
    height: 100%;
    width: 6.3rem;
    background-color: get-colour('grey-lighter-90');
    @include flex(column, center, flex-start);
    // @include padding(horizontal, spacing-02);
    &--wide {
      @extend .order-pricing-fluor-grid__column;
      width: 100%;
      min-width: 6.3rem;
      white-space: nowrap;
    }
  }

  &__clarities {
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-color: get-colour('grey-lighter-95');
  }

  &__label {
    @extend .label;
    text-align: center;
    @include margin(vertical);
  }

  &__fluor-values {
    width: 60px;
    display: none;

    &--expanded {
      @include flex(column, flex-start, center);
      height: 100%;
      width: 100%;
      &-checkered {
        & > *:nth-child(odd) {
          background-color: get-colour('grey-lighter-80');
        }
      }
    }
  }

  &__fluor-value {
    @include flex(column, center, flex-start);
    flex-grow: 0;
    width: 100%;
    @include padding(horizontal, spacing-02);
    & > * {
      @include margin(bottom);
    }
    &-grow {
      @extend .order-pricing-fluor-grid__fluor-value;
      flex-grow: 1;
    }
  }

  &__projections-toggle {
    @include margin(left);
  }

  &__fluor-calculation {
    @include margin(left);
  }

  &__fluor-subtext {
    @include flex(column);
    @include padding(vertical, spacing-02);
    @include padding(horizontal);
    color: get-colour('grey-lighter-20');

    &-placeholder {
      color: transparent;
      @include margin(top);
    }
  }

  .form-warning {
    input {
      border-color: get-colour('warning') !important;
    }
  }
  .form-error {
    input {
      border-color: get-colour('error') !important;
    }
  }
  .input__helper-text-container {
    @include margin(top, spacing-01);
    text-align: center;
  }
}

.order-pricing-fluor-grid__clarities:nth-child(2) {
  border-left: 1px solid get-colour('grey-lighter-95')
}

.order-pricing-step-top-buttons {
  @include flex(row, center, flex-start);
  background-color: get-colour('grey-lighter-95');
  border : 1px solid get-colour('grey-lighter-60');
  @include padding(vertical, spacing-02);
  @include margin(bottom);

  & > * {
    @include margin(horizontal, spacing-01);
  }
}

.export-price-template {
  &__dropdown-container {
    @include margin(right, spacing-03);
  }
  &__row {
    width: 100%;
    min-width: 19rem;
    @include margin(bottom, spacing-03);
    @include flex(row, center, flex-start);

      & > * {
        @include margin(right);
      }
  }
  &__dropdown {
    width: 100%;
  }
  &__buttons {
    @include flex(row, center, flex-end);
    @include margin(top, spacing-03);

    & > * {
      @include margin(left);
    }
  }
}

.order-pricing-infotip{
  &__instructions {
    max-height: 400px;
    overflow-y: auto;

    // Firefox scrollbar style
    scrollbar-width: thin;
    scrollbar-color: get-colour('white') #222;

    // Chrome scrollbar style
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #222;
    }
    &::-webkit-scrollbar-thumb {
      background-color: get-colour('white');
      border-radius: 3px;
      border-style: none;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}