.proportion-input {
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 4rem;
    gap: 10px;
    width: 100%;
    @include margin(bottom);
  }

  &__label {
    grid-column: 1;

    & label {
      height: 100%;

      & div.input__label {
        height: 100%;
      }
    }
  }

  &__minmax {
    grid-column: 2 / 4;
  }
  &__min {
  grid-column: 2;
  }
  &__max {
    grid-column: 3;
  }
  &__units {
    grid-column: 4;

    & label {
      height: 100%;

      & div.input__label {
        height: 100%;
        font-weight: 500;
      }
    }
  }
}