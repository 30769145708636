.badge {
  position: relative;
  &-counter {
    @extend .p3;
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    top: -4px;
    right: -8px;
    background-color: get-colour('red');
    border-radius: 25px;
    color: get-colour('white');
    @include flex();
  }
}