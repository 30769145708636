// Do not set width and height directly to buttons properties.
// Width and height are responsive to the button content's size
// by it's padding values ($btn-padding).

.btn {
  display: inline;
  box-sizing: border-box;
  font-family: inherit;
  border-radius: $btn-border-radius;
  transition: $btn-transition;
  // This is the icon button width.
  // This is also to avoid very small buttons.
  min-width: $btn-min-width;

  &--sm {
    @extend .btn;
    @include btn-padding(sm);
  }

  &--md {
    @extend .btn;
    @include btn-padding(md);
  }

  &--lg {
    @extend .btn;
    @include btn-padding(lg);
  }

  &:disabled {
    cursor: default;
  }

  &__spinner {
    @include margin(left);
  }

  &--no-padding {
    @extend .btn;
    padding: 0;
  }
}

.btn-primary {
  background-color: get-colour('btn-primary-default-bg');
  color: get-colour('btn-primary-default-text');
  border: get-colour('btn-secondary-default-border') 1px solid;

  &:hover {
    background-color: get-colour('btn-primary-hover-bg');
    box-shadow: $btn-hover-drop-shadow;
  }

  &:active {
    background-color: get-colour('btn-primary-active-bg');
    box-shadow: $btn-active-drop-shadow;
  }

  &:disabled {
    background-color: get-colour('btn-primary-disabled-bg');
    color: get-colour('btn-primary-disabled-text');
    border: get-colour('btn-primary-disabled-border') 1px solid;
    box-shadow: none;
  }
}

.btn-secondary {
  background-color: get-colour('btn-secondary-default-bg');
  color: get-colour('btn-secondary-default-text');
  border: get-colour('btn-secondary-default-border') 1px solid;

  &:hover {
    background-color: get-colour('btn-secondary-hover-bg');
    color: get-colour('btn-secondary-hover-text');
    box-shadow: $btn-hover-drop-shadow;
  }

  &:active {
    background-color: get-colour('btn-secondary-active-bg');
    box-shadow: $btn-active-drop-shadow;
  }

  &:disabled {
    background-color: get-colour('btn-secondary-disabled-bg');
    color: get-colour('btn-secondary-disabled-text');
    border: get-colour('btn-secondary-disabled-border') 1px solid;
    box-shadow: none;
  }
}

.btn-warning {
  background-color: get-colour('btn-warning-default-bg');
  color: get-colour('btn-warning-default-text');
  border: get-colour('btn-warning-default-border') 1px solid;

  &:hover {
    background-color: get-colour('btn-warning-hover-bg');
    box-shadow: $btn-hover-drop-shadow;
  }

  &:active {
    background-color: get-colour('btn-warning-active-bg');
    box-shadow: $btn-active-drop-shadow;
  }

  &:disabled {
    background-color: get-colour('btn-warning-disabled-bg');
    color: get-colour('btn-warning-disabled-text');
    box-shadow: none;
  }
}

.btn-error {
  background-color: get-colour('btn-error-default-bg');
  color: get-colour('btn-error-default-text');

  &:hover {
    background-color: get-colour('btn-error-hover-bg');
    box-shadow: $btn-hover-drop-shadow;
  }

  &:active {
    background-color: get-colour('btn-error-active-bg');
    box-shadow: $btn-active-drop-shadow;
  }

  &:disabled {
  background-color: get-colour('btn-error-disabled-bg');
    color: get-colour('btn-error-disabled-text');
    box-shadow: none;
  }
}

.btn-action {
  background-color: get-colour('btn-action-default-bg');
  color: get-colour('btn-action-default-text');

  &:hover {
    color: get-colour('btn-action-hover-text');
  }

  &:active {
    color: get-colour('btn-action-active-text');
  }

  &:disabled {
    color: get-colour('btn-action-disabled-text');
  }
}

.btn-icon {
  background-color: get-colour('btn-icon-default-bg');
  color: get-colour('btn-icon-default-text');
  border: get-colour('btn-icon-default-border') 1px solid;
  min-width: min-content;

  &:hover {
    background-color: get-colour('btn-icon-hover-bg');
    color: get-colour('btn-icon-hover-text');
    box-shadow: $btn-hover-drop-shadow;
  }

  &:active {
    background-color: get-colour('btn-icon-active-bg');
    box-shadow: $btn-active-drop-shadow;
  }

  &:disabled {
    background-color: get-colour('btn-icon-disabled-bg');
    color: get-colour('btn-icon-disabled-text');
    border: get-colour('btn-icon-disabled-border') 1px solid;
    box-shadow: none;
  }

  &--no-outline {
    @extend .btn-icon;
    background-color: get-colour('btn-icon-no-outline-default-bg');
    border: get-colour('btn-icon-no-outline-default-border') 1px solid;

    & svg {
      color: get-colour('btn-icon-no-outline-default-text');
    }

    &:hover {
      background-color: get-colour('btn-icon-no-outline-hover-bg');
      box-shadow: none;

      & svg {
        color: get-colour('btn-icon-no-outline-hover-text');
      }
    }
  }
}
