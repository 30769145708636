.progressive-profit-matrix {
  
}

.progressive-profit-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2.5em;

  & > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
  }
  & :first-child {
    margin-left: 0;
  }
}

.progressive-profit-row__add {
  margin-top: 0.5rem;
}

.assortment-list__progressive-profit-modal-content > .modal > .modal__content {
  width: 800px;
  max-width: 70vw;
}