// Positioning
@mixin flex($direction: row, $align-items: center, $justify-content: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

// Spacing
// The spacing system is controlled by the root font-size.
// $horizontal-spaces is the value of each horizontal step.
// $vertical-spaces is the value of each vertical step.
// Those values come from Clara's style guide
// TODO: add confluence link to the style guide

// Maps that defines key value pair of
// well-defined horizontal and vertical spacing range.
// Spacing is based on the root font-size and defined
// in incremental steps.
// The reason it's based on font-size it's because in the case
// body's font-size changes, the whole layout spacing dynamically
// adapts. This is very useful for layout responsiveness for example.
$horizontal-spaces: (
  none: 0,
  spacing-01: $base-spacing * 0.5,
  spacing-02: $base-spacing,
  spacing-03: $base-spacing * 1.5,
  spacing-04: $base-spacing * 2,
  spacing-05: $base-spacing * 2.5,
  spacing-06: $base-spacing * 3,
  spacing-07: $base-spacing * 3.5,
  spacing-08: $base-spacing * 4,
  spacing-09: $base-spacing * 4.5,
  spacing-10: $base-spacing * 5,
  spacing-11: $base-spacing * 5.5,
  spacing-12: $base-spacing * 6,
  spacing-13: $base-spacing * 6.5,
  spacing-14: $base-spacing * 7,
  spacing-15: $base-spacing * 7.5,
  spacing-16: $base-spacing * 8,
  spacing-17: $base-spacing * 8.5,
  spacing-18: $base-spacing * 9,
  spacing-19: $base-spacing * 9.5,
  spacing-20: $base-spacing * 10,
);

$vertical-spaces: (
  none: 0,
  spacing-01: $base-spacing * 0.25,
  spacing-02: $base-spacing * 0.5,
  spacing-03: $base-spacing * 0.75,
  spacing-04: $base-spacing,
  spacing-05: $base-spacing * 1.25,
  spacing-06: $base-spacing * 1.5,
  spacing-07: $base-spacing * 1.75,
  spacing-08: $base-spacing * 2,
  spacing-09: $base-spacing * 2.25,
  spacing-10: $base-spacing * 2.5,
  spacing-11: $base-spacing * 2.75,
  spacing-12: $base-spacing * 3,
  spacing-13: $base-spacing * 3.25,
  spacing-14: $base-spacing * 3.5,
  spacing-15: $base-spacing * 3.75,
  spacing-16: $base-spacing * 4,
  spacing-17: $base-spacing * 4.15,
  spacing-18: $base-spacing * 4.5,
  spacing-19: $base-spacing * 4.75,
  spacing-20: $base-spacing * 5,
);

@mixin padding($direction: all, $spacing: spacing-01, $important: false) {
  @include add-space(padding, $direction, $spacing, $important);
}

@mixin margin($direction: all, $spacing: spacing-01, $important: false) {
  @include add-space(margin, $direction, $spacing, $important);
}

@mixin add-space(
  $attribute,
  $direction: all,
  $spacing: spacing-01,
  $important: false
) {
  @if $direction == all {
    @include spacer(
      #{$attribute}-left,
      $horizontal-spaces,
      $spacing,
      $important
    );
    @include spacer(
      #{$attribute}-right,
      $horizontal-spaces,
      $spacing,
      $important
    );
    @include spacer(#{$attribute}-top, $vertical-spaces, $spacing, $important);
    @include spacer(
      #{$attribute}-bottom,
      $vertical-spaces,
      $spacing,
      $important
    );
  } @else if $direction == left {
    @include spacer(
      #{$attribute}-left,
      $horizontal-spaces,
      $spacing,
      $important
    );
  } @else if $direction == right {
    @include spacer(
      #{$attribute}-right,
      $horizontal-spaces,
      $spacing,
      $important
    );
  } @else if $direction == top {
    @include spacer(#{$attribute}-top, $vertical-spaces, $spacing, $important);
  } @else if $direction == bottom {
    @include spacer(
      #{$attribute}-bottom,
      $vertical-spaces,
      $spacing,
      $important
    );
  } @else if $direction == horizontal {
    @include spacer(
      #{$attribute}-left,
      $horizontal-spaces,
      $spacing,
      $important
    );
    @include spacer(
      #{$attribute}-right,
      $horizontal-spaces,
      $spacing,
      $important
    );
  } @else if $direction == vertical {
    @include spacer(#{$attribute}-top, $vertical-spaces, $spacing, $important);
    @include spacer(
      #{$attribute}-bottom,
      $vertical-spaces,
      $spacing,
      $important
    );
  }
}

@mixin spacer($attribute, $spacing, $unit, $important) {
  @if ($important) {
    #{$attribute}: map-get($spacing, $unit) !important;
  } @else {
    #{$attribute}: map-get($spacing, $unit);
  }
}

// Breakpoints
$breakpoints: (
  // Small devices (portrait phones, 576px and up)
  phone: 360px,
  // Medium devices (tablets, 768px and up)
  tablet: 768px,
  // Large devices (desktops, 992px and up)
  desktop: 992px,
  // Extra large devices (large desktops, 1200px and up)
  large-desktop: 1200px
);

@mixin for-screen-size($size: desktop, $direction: up) {
  @if map-has-key($breakpoints, $size) {
    @if $direction == up {
      @media (min-width: map-get($breakpoints, $size)) {
        @content;
      }
    }
    else {
      @media (max-width: map-get($breakpoints, $size)) {
        @content;
      }
    }
  }
}

// ----------------------------------------------------------------------
// Typography
// ----------------------------------------------------------------------
@mixin bold($font-weight) {
  // we add !important here because this rule usually overrides the original
  // class font-weight where this mixin is included.
  font-weight: $font-weight !important;
}

// ----------------------------------------------------------------------
// Components
// ----------------------------------------------------------------------

// - Buttons
@mixin btn-padding($size) {
  @if ($size == sm) {
    padding: $btn-padding-vertical-sm $btn-padding-horizontal-sm;
  } @else if ($size == md) {
    padding: $btn-padding-vertical-md $btn-padding-horizontal-md;
  } @else if ($size == lg) {
    padding: $btn-padding-vertical-lg $btn-padding-horizontal-lg;
  }
}

@mixin btn-border($colour-name) {
  border: get-colour($colour-name) $btn-border-width * 1px;
}

// - Inputs

@mixin input-label() {
  color: get-colour('grey');
  font-weight: 600;

  @extend .p2;
  @include margin(bottom);
  @include flex(row, center, flex-start);
}

@mixin input-border() {
  border-style: $input-border-style;
  border-width: $input-border-width;
  border-radius: $global-border-radius !important;
  border-color: $input-border-color;

  &:disabled {
    border-color: $input-border-color-disabled;
  }
}

@mixin input-padding() {
  @include padding(vertical, spacing-03);
  @include padding(horizontal, spacing-02);
}

@mixin input-margin() {
  @include margin(bottom, spacing-06);
}

@mixin input-optional-label-margin() {
  @include margin(left, spacing-01);
}

@mixin input-border-focused() {
  border-color: $input-border-color-focused !important;
  box-shadow: $input-drop-shadow-focused !important;
  outline: none;
}

@mixin create-colour-class-names() {
  // this mixin loops through colours map
  // and create class names for colours and fills
  // based on the color name in the colours map
  // output example:
  // green-lighter-10--colour
  // green-lighter-10--fill
  @each $colour, $hex in $colours {
    .#{$colour}--colour {
      color: $hex;
    }

    .#{$colour}--fill {
      fill: $hex;
    }
  }
}

@mixin drop-shadow($size: md) {
 @if $size == md  {
   box-shadow: $drop-shadow-md;
 }
 @if $size == xxl  {
   box-shadow: $drop-shadow-xxl;
 }
}

@include create-colour-class-names();


