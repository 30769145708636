.drag-and-drop {
  background-color: get-colour('white');
  border: 2px dashed get-colour('grey-lighter-60');
  width: 100%;
  border-radius: 4px;
  transition: all .4s ease-in-out;
  @include flex(column, center, center);
  @include padding(all, spacing-04);

  & span {
    @include margin(bottom,spacing-03);
  }

  &__title {
    @extend .h4;
    color: get-colour('secondary');
  }

  &__file-input {
    display: none;
  }

  &__file-types {
    @extend .subtext;
    color: get-colour('secondary-text');
    @include margin(bottom, spacing-02);
  }

  &--is-drag-over{
    @extend .drag-and-drop;
    border-color: get-colour('grey');
    background-color: get-colour('green-lighter-90');
  }
}