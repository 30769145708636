.signin {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: get-colour('white');
  @include flex(row, flex-start, flex-end);


  &__hero-container {
    display: none;
    width: 100%;
    max-width: 2000px;
    height: 100vh;

    @include for-screen-size(desktop, up) {
      @include flex(column, center, flex-start);
    }

    &-img {
      display: none;
      position: relative;
      margin: 0 auto;
      max-width: 100%;
      max-height: 100%;
      @include for-screen-size(desktop, up) {
        @include flex(row, flex-end, center);
      }

      & img {
        bottom: 0;
        position: fixed;
        max-height: 78%;
        max-width: 78%;
        display: block;
        object-fit: contain;
      }
    }
  }

  &__container {
    z-index: 1;
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: get-colour('primary');
    @include flex(column, center, center);
    @include for-screen-size(desktop, up) {
      width: 40%;
      max-width: 760px;
      min-width: 450px;
      @include padding(horizontal, spacing-10);
      @include flex(column, flex-start, flex-start);
    }
  }

  &__logo {
    width: 455px;
    max-width: 100%;
    @include margin(bottom, spacing-15);
    @include for-screen-size(desktop, up) {
      width: 455px;
      @include flex(column, center, center);
    }

    & img {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      @include margin(top, spacing-15);
    }
  }

  &__subtext {
    @extend .p1;
    font-size: 1.1rem;
    width: 100%;
    text-align: center;
    opacity: .7;
    color: get-colour('grey-darker-20');
    @include margin(top, spacing-03);
  }

  &__form {
    width: 90%;

    &-title {
      @extend .h1;
      color: get-colour('white');
      @include padding(left, spacing-04);
    }

    &-logo {
      width: 455px;
      max-width: 60%;
      color: get-colour('white');
      filter: brightness(100);
      @include margin(bottom, spacing-15);

      & img {
        margin: 0 auto;
        width: 100%;
        height: 100%;
      }

      @include for-screen-size(desktop, up) {
        display: none;
      }

      @include flex(column, center, center);
      @include margin(bottom, spacing-15);
    }

    &-subtext {
      @extend .p1;
      @include margin(vertical, spacing-03);
      text-align: center;
    }

    &-container {
      z-index: 3;
      width: 100%;
      height: 100%;
      @include flex(column, center, center);
    }

    &-buttons {
      float: right;
      @include margin(top, spacing-04);
    }

    &-signin-buttons {
      @include margin(top, spacing-06);
      @include flex(row, center, space-between);
    }

    &-action-button-text {
      color: get-colour('white');
      opacity: .7;
    }

    &-forgot-password-button {
      font-size: 0.8rem;
    }
  }

  &__confirm-password {
    width: 40%;
  }

}