h1,
.h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;
  font-weight: $headers-font-weight;
}

h2,
.h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;
  font-weight: $headers-font-weight;
}

h3,
.h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
  font-weight: $headers-font-weight;
}

h4,
.h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
  font-weight: $headers-font-weight;
}

h5,
.h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
  font-weight: $headers-font-weight;
}

.p1 {
  font-size: $p1-font-size;
  line-height: $p1-line-height;
  font-weight: $p1-font-weight;
}

.p2 {
  font-size: $p2-font-size;
  line-height: $p2-line-height;
  font-weight: $p2-font-weight;
}

.p3 {
  font-size: $p3-font-size;
  line-height: $p3-line-height;
  font-weight: $p3-font-weight;
}

.subtext {
  font-size: $subtext-font-size;
  line-height: $subtext-line-height;
  font-weight: $subtext-font-weight;
}

.label {
  font-size: $label-font-size;
  line-height: $label-line-height;
  font-weight: $label-font-weight;
}

.button-text {
  @extend .label;

  &--sm {
    @extend .button-text;
    font-size: $button-font-size-sm;
    line-height: $button-line-height-sm;
  }

  &--md,
  &--lg {
    @extend .button-text;
  }

  @include flex();
}

.overline {
  font-size: $overline-font-size;
  line-height: $overline-line-height;
  font-weight: $overline-font-weight;
}

.center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap; 
}