.rough-stone-list {

  &__comment {
    position: relative;
    cursor: pointer;
    width: 42px;
    height: 32px;

    &-counter {
      @extend .overline;
      min-width: 16px;
      min-height: 16px;
      position: absolute;
      right: 12px;
      bottom: 7px;
      background-color: get-colour('grey-lighter-80');
      color: get-colour('text');
      border-radius: 50%;
      @include flex();
      padding: 1px 2px;
    }

    &-icon {
      fill: get-colour('secondary');
    }
  }

  &__organization-dropdown {
    width: 100%;
    @include margin(bottom, spacing-06);
    @include padding(right, spacing-02);
  }

  &__reports-row {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: .5fr 2.5fr auto;
    border-bottom: 1px solid get-colour('grey-lighter-80');
    @include padding(vertical, spacing-02);
    @include margin(bottom, spacing-03);

    & > * {
      @include margin(right, spacing-02);
    }
  }

  &__bids-tab-icon {
    @include flex();

    & svg {
      color: get-colour('red');
    }

    & > * {
      @include margin(right);
    }
  }

  &__download-file-modal {
    &-content{
      > .modal {
          max-height: 90vh;
          overflow: hidden;
          @include padding(all, none);
          > .modal__header {
            @include padding(horizontal, spacing-02);
            @include margin(vertical, spacing-01);
          }
      }
    }
  }
}

.update-selected-stones-modal-content > .modal > .modal__content {
  width: 500px;
}
