.dashboard {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  @include flex(column, center, flex-start);

  &__info-cards {
    width: 100%;
    display: grid;
    gap: 1rem;
    @include margin(bottom, spacing-08);

    @include for-screen-size(phone, up) {
      grid-template-columns: repeat(1, 1fr);
    }

    @include for-screen-size(tablet, up) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include for-screen-size(desktop, up) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include for-screen-size(large-desktop, up) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__input-cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 1rem;
    @include margin(bottom, spacing-08);
  }
}

.dashboard-links__container {
  display: grid;
  grid-template-columns: 8rem 1fr 1fr;
  max-height: 80vh;

  & > div {
    margin: 0 1rem 1rem 0;

    & .link-component {
      flex-direction: row;
    }
  }
}
