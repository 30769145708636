.form-row {
  width: 100%;
  @include padding();
  &__fields {
    @include flex(row, flex-start, flex-start);

    & > * {
      @include margin(right);
    }
  }
}