.dropdown-button {
  @extend .table-filter-popup;
  &__content {
    @extend .table-filter-popup__content;
    border-radius: 2px;
    padding: 0;
    min-width: 200px;
    &--hidden {
      @extend .table-filter-popup__content--hidden;
    }
  }
  &__group {
    @extend .dropdown__group;
    &-heading {
      @extend .dropdown__group-heading;
    }
  }
  &__option {
    @extend .dropdown__option;
    @include padding(vertical, spacing-01);
    @include padding(horizontal, spacing-01);
    > button {
      width: 100%;
      > * {
        justify-content: left;
      }
    }
  }
  .no-options {
    @extend .dropdown__option--is-disabled;
    > button {
      width: 100%;
      > * {
        justify-content: center;
      }
    }
  }
}
