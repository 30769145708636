.error-banner-container {
  .error-banner{
    padding: 10px 10px;
    display: flex;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .form-warning {
    background-color: get-colour('banner-warning') !important;
  }
  .form-error {
    background-color: get-colour('banner-error') !important;
  }
}

