.assortment-details {
  width: 100%;
  height: 100%;
  @include flex(column, flex-start, center);
}

.assortment-details__details-container {
  width: 100%;
  height: auto;


  @include for-screen-size(large-desktop, up) {
    width: 60%
  }

  @include flex(column, flex-start, center);
}

.assortment-details__details-header {
  @include flex(row, center, space-between);
}

.assortment-details__details-panel {
  width: 100%;
  height: 100%;
  background-color: get-colour('white');
  border-radius: 2px;
  border: 1px solid get-colour('grey-lighter-60');
  @include flex(column, center, center);
}

.assortment-details__details-panel-item {
  @include flex(column, center, center);
}

.assortment-details__details-panel-row {
  @extend .p1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  padding: 0.5rem;
  border-bottom: 1px solid get-colour('grey-lighter-90');

}

.assortment-details__rough-list {
  width: 100%;
  @include margin(top, spacing-06);

  &-header {
    width: 100%;
    @include flex(row, flex-end, flex-end);
    @include margin(bottom);
  }
}

.assortment-details__rough-list-search {
  width: 100px;
}

.assortment-details__rough-list-items {
  width: 100%;
  background-color: get-colour('white');
  @include flex(column, center, flex-start);
}

.assortment-details__rough-list-container {
  width: 100%;
  border-radius: 2px;
  border-top: 1px solid get-colour('grey-lighter-60');
  border-bottom: 1px solid get-colour('grey-lighter-60');
  @include flex(column, center, center);
}

.assortment-details__rough-list-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-right: 1px solid get-colour('grey-lighter-60');
  border-left: 1px solid get-colour('grey-lighter-60');
  border-bottom: 1px solid get-colour('grey-lighter-95');
  @include padding(all, spacing-02);
}

.assortment-details__rough-card-item {
  @include margin(bottom, spacing-03);
  @include flex(column, flex-start, flex-start);
}

.assortment-details__rough-card-item-label,
.assortment-details__rough-card-item-value {
  @extend .p2;
}

.assortment-details__rough-card-item-label {
  @include margin(bottom);
}

.assortment-details__rough-list-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-bottom: 1px solid get-colour('grey-lighter-60');
}

.assortment-details__details-header-edit-icon svg {
  color: get-colour('primary');
}

.assortment-details__rough-list-footer {
  width: 100%;
  @include padding(top);
  @include flex(row, center, space-between);
}

.assortment-details__rough-list-select-multiple {
  @include flex(row, center, center);
}

.assortment-details__rough-list-pagination {
  @include flex();

  &-btn {
    width: 24px;
    height: 30px;
    border-radius: 2px;
    border: 1px solid get-colour('grey-lighter-40');
    background-color: get-colour('white');
    @include flex();
    @include margin(left);

    &:hover {
      transition: .1s ease-in-out;
      background-color: get-colour('grey-lighter-90');
    }

    &-text {
      @extend .button-text;
      color: get-colour('grey-lighter-20');
    }
  }
}

.assortment-details__rough-list-edit {
  text-decoration: none;
  color: get-colour('text');
}

.assortment-details-provenance-modal {
  &-content{
    > .modal {
      max-width: 90vw;
    }
  }
  &__form {
    @extend .form__container;
  }
  &__table{
    display: grid;
    grid-template-columns: 1fr 2.5fr;

    &-cell {
      @extend .assortment-details-provenance-modal__table;
      grid-template-columns: 1fr 1fr;
      align-items: center;

      &-value {
        width: min-content;

        &--new {
          color: get-colour('lime-darker-20');
        }

        &--remove {
          color: get-colour('error');
          text-decoration: line-through;
        }
      }
    }
  }
}
