// TODO:Apply IBM Plex sans font-family

body,
textarea {
  font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: get-colour('text');
}

html {
  overflow: hidden;
}

a,
button {
  cursor: pointer;
  font-family: inherit;
}

a {
  @extend .link;
}

hr {
  border-color: get-colour('grey-lighter-80');
  border-top: 1px;
  @include margin(vertical, spacing-04);
}

fieldset {
  border: 1px solid get-colour('grey-lighter-60');
  @include padding(all, spacing-03);
  @include margin(bottom, spacing-03);
}

fieldset legend {
  font-weight: 600 !important;
  @include input-label();
}

input[type='radio'] {
  appearance: radio;
  @include margin(right, spacing-01);
}

input[type='radio'] + label {
  @include margin(right, spacing-02);
}

textarea {
  resize: none;
}

*:focus {
  outline: none;
}
// This adds the outline border when the user
// navigates between elements pressing tab key on
// keyboard only. Outlines on button clicks for example are disabled.
*:focus-visible {
  outline: 1px solid get-colour('green-lighter-20');
}

::placeholder {
  color: get-colour('grey-lighter-60');
}
