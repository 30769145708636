.assign-rough-stone-list {
  &__quantity-filled {
    color: get-colour('warning');
  }
}
.order-column {
  &__info {
    @include flex();
    @include margin(right, spacing-01);
    &-name {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}