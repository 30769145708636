.baseline-sheet {
  font-size: 12px;
  display: grid;
  grid-template-columns: auto auto;
  &__weight-grid {
    border: 1px solid get-colour('black');
    border-radius: 2px;
    @include margin(all, spacing-02);
    &-label {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      background: get-colour('black');;
      color: get-colour('white');
      padding: 10px;
    }
    &-columns {
      @include flex(row, flex-end);
      &-header {
        text-align: center;
        font-weight: bold;
        @include padding(all, spacing-02);
        width: 65px;
        border-bottom: 1px solid get-colour('black');;
      }
    }
    &-rows{
      @include flex(column)
    }
    &-row{
      @include flex(row, flex-end);
      &-header {
        text-align: center;
        font-weight: bold;
        @include padding(all, spacing-02);
        border-right: 1px solid get-colour('black');;
      }
      &-cell {
        text-align: center;
        @include padding(all, spacing-02);
        width: 65px;
      }
      &:nth-child(odd) {
        background-color: get-colour('grey-lighter-95');
      }
    }
  }
  .spacer {
    width: 65px;
  }
}