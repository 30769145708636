.log-view {

  &__search-section {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & > * {
      margin-top: 0.3rem;
    }
    & > *:nth-child(odd) {
      margin-right: 0.5rem;
    }
    & > *:nth-child(even) {
      margin-left: 0.5rem;
    }
  }

  &__list-section {
    margin-top: 1rem;
  }

  &__detail-section {
    margin-top: 1rem;
  }
}

.log-details {
  width: 100%;
  &__row {
    width: 100%;
    height: fit-content;
    background-color: get-colour('white');
    border: 1px solid get-colour('grey-lighter-60');
    border-radius: 2px;
    @include padding(all, spacing-02);
  }
  &__container {
    position: relative;
    border:1px solid get-colour('grey-lighter-60');
  }
  &__header {
    @extend .h3;
    @include flex(row, center, space-between);
    @include padding(all, spacing-03);
    border-bottom: 1px solid get-colour('grey-lighter-80');
    background-color: get-colour('grey-lighter-90');
  }
  &__label {
    &-description {
      @extend .h4;
      font-weight: 400;
    }
  }
  &__column-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include padding(all, spacing-02);
    
    & > *:nth-child(odd) {
     @include margin(right, spacing-01);
     @include margin(top, spacing-01);
    }
    & > *:nth-child(even) {
      @include margin(left, spacing-01);
      @include margin(top, spacing-01);
    }
  }

  &__attribute-container {
    margin-top: 0.5rem;
    @include padding(all, spacing-02);
  }

  &__value-row {
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;
    @include padding(all, spacing-02);
    
    & > * {
      margin-right: 0.5rem;
    }
    & > *:nth-child(3n) {
      margin-left: 0.5rem;
    }
 
    & textarea {
      height: 4.5rem;
      resize: vertical;
    }
  }

  &__download-anchor {
    @include flex(column, center, space-around);
  }
}