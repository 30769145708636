.organization-details {
  &__suspend-orders-rough-modal {

    &-buttons {
      width: 100%;
      @include flex(row, center, flex-end);
      & > * {
        @include margin(left);
      }
    }
  }
  &__stones-checkbox {
    &-container {
      max-width: 50vw;
      @include flex(row, center, flex-start);
      .checkbox__container {
        width: 1.5rem;
      }
    }
  }
}