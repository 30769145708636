.notification-attachment-list {
  width: 100%;
  @include flex(row, baseline, flex-start);
  &__row {
    @extend .notification-attachment-list;
    @include flex(row, flex-start, flex-start);
    @include margin(bottom, spacing-02);
    & > :first-child {
      @include margin(right);
    }
  }

  &__icons {
    @include flex();
    & > * {
      @include padding(all);
    }
  }
}